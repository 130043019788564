import { useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Typography,
} from "@material-ui/core";
import { Instagram, Place, AlternateEmail } from "@material-ui/icons";
import { Lightbox, LightboxHeader } from "./common/Lightbox.jsx";

export default function Footer() {
  const [openContact, setOpenContact] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const handleOpenContact = () => setOpenContact(true);
  const handleCloseContact = () => setOpenContact(false);

  const handleOpenLocation = () => setOpenLocation(true);
  const handleCloseLocation = () => setOpenLocation(false);
  return (
    <>
      <ContactInfo open={openContact} handleClose={handleCloseContact} />
      <Location open={openLocation} handleClose={handleCloseLocation} />
      <BottomNavigation
        className="semi-transparent"
        style={{ backgroundColor: "rgba(255,255,255,0.8)" }}
      >
        <BottomNavigationAction
          href="https://www.instagram.com/easyyolk/"
          target="_blank"
          label="Instagram"
          icon={<Instagram />}
        />
        <BottomNavigationAction
          label="Contact"
          icon={<AlternateEmail />}
          onClick={handleOpenContact}
        />
        <BottomNavigationAction
          label="Location"
          icon={<Place />}
          onClick={handleOpenLocation}
        />
      </BottomNavigation>
    </>
  );
}

function ContactInfo({ open, handleClose }) {
  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Contact Us" />
      <Typography>Text/Call: (949) 818-6591</Typography>
      <Typography>Email: katie@easy-yolk.com</Typography>
    </Lightbox>
  );
}

function Location({ open, handleClose }) {
  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Location" />
      <Typography>We do not have a storefront location.</Typography>
      <Typography>Pickups will take place by appointment at</Typography>
      <Typography>2461 Deodar St., Santa Ana, CA 92705</Typography>
    </Lightbox>
  );
}
