import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ShoppingCart, Clear } from "@material-ui/icons";
import {
  addProductPicURL,
  deleteTag,
  toggleFeatured,
  toggleVisibility,
} from "../../app/firestoreClient";
import { TagsStyling, QuantityField } from "../CustomInputFields";
import { deleteProductPic } from "../../app/utils/deleteFromStorage";
import { UploadImage } from "../../app/utils/UploadFiles";
import {
  Edit,
  StarOutline,
  Star,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import parse from "html-react-parser";
import { toHexCode } from "../../app/utils/utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    marginBottom: 10,
    marginTop: 10,
    marginRight: 10,
  },
  coatingSelect: {
    minWidth: 310,
    marginBottom: 10,
    marginTop: 10,
  },
}));

export function ProductDetails({
  cartItems,
  colName,
  currentUser,
  product,
  openEditForm,
  openTagForm,
  setCartItems,
}) {
  const description = product?.description || "";
  const longDescription = product?.longDescription;
  const name = product?.name || "";
  const minQuantity = product?.minQuantity || 1;
  const price = product?.price?.toFixed(2) || "";
  const tags = product?.tags || [];
  const pictures = product?.pictures || [];
  const cartItemIDs = cartItems.map((cartItem) => cartItem.id);
  const addedToCart = cartItemIDs.includes(product.id);
  const addedItem = cartItems.find((item) => item.id === product?.id);
  const addedQuantity = addedItem?.quantity;

  const selectStyle = useStyles();
  const classes = TagsStyling();

  const [file, setFile] = useState(null);
  const [personalization, setPersonalization] = useState(
    addedItem?.personalization || ""
  );
  const [selFlavor, setSelFlavor] = useState(addedItem?.flavor || "Vanilla");
  const [selTopping, setSelTopping] = useState(
    addedItem?.topping || "Sprinkles"
  );
  const [selCoating, setSelCoating] = useState(
    addedItem?.coating || "dark chocolate"
  );
  const [quantity, setQuantity] = useState(
    addedToCart ? addedQuantity : minQuantity
  );
  const [selectedURL, setSelectedURL] = useState(pictures[0]);
  const picSelected = typeof selectedURL === "string";

  const updateQuantity = (e) => setQuantity(e.target.value);
  const handlePersonalization = (e) => setPersonalization(e.target.value);
  const handleFlavor = (e) => setSelFlavor(e.target.value);
  const handleTopping = (e) => setSelTopping(e.target.value);
  const handleCoating = (e) => setSelCoating(e.target.value);

  function updateCart() {
    const itemDetails = {
      id: product.id,
      name: product.name,
      price: product.price,
      pictures: product.pictures,
      quantity: quantity,
    };

    if (product.allowPersonalization)
      itemDetails.personalization = personalization;
    if (product.hasFlavors) itemDetails.flavor = selFlavor;
    if (product.hasToppings) itemDetails.topping = selTopping;
    if (product.hasCoating) itemDetails.coating = selCoating;

    const updatedItems = cartItems.map((cartItem) => {
      if (cartItem.id === product.id) return itemDetails;
      else return cartItem;
    });

    !addedToCart && setCartItems([...cartItems, itemDetails]);
    addedToCart && setCartItems(updatedItems);
  }

  function handleDeleteTag(tag) {
    deleteTag(colName, product.id, tag);
  }

  function handleFeaturedToggle() {
    toggleFeatured(colName, product);
  }

  function handleVisibilityToggle() {
    toggleVisibility(colName, product);
  }

  useEffect(() => {
    pictures?.length === 1 && setSelectedURL(pictures[0]);
    //eslint-disable-next-line
  }, [product]);

  if (!product) return null;

  return (
    <Box className="product-details-container">
      <Box className="product-details-imgs">
        <Fade in={picSelected || false} timeout={1200}>
          <img width="100%" src={selectedURL} alt="selected cookie" />
        </Fade>
        {currentUser && pictures?.length > 0 && (
          <IconButton
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              backgroundColor: "rgba(0,0,0,0.2)",
              color: "silver",
            }}
            onClick={() =>
              deleteProductPic(colName, product, selectedURL, setSelectedURL)
            }
          >
            <Clear />
          </IconButton>
        )}
        <Fade in={picSelected || false} timeout={2000}>
          <Box>
            {pictures.map((url, ind) => (
              <img
                key={`picture-${ind}`}
                width="19.3%"
                src={url}
                alt={`item ${ind + 1}`}
                onClick={() => setSelectedURL(url)}
                className="hover-pointer"
                style={{
                  borderStyle: "solid",
                  borderColor: url === selectedURL ? "#EFD042" : "#FFFFFF",
                  borderWidth: url === selectedURL ? "3px 3px" : "3px 1px",
                }}
              />
            ))}
          </Box>
        </Fade>

        {currentUser && (
          <UploadImage
            file={file}
            setFile={setFile}
            storagePath={`${colName}/${file?.name}`}
            makeRef={() => addProductPicURL(colName, product, file)}
          />
        )}
      </Box>

      <Box className="product-details-info">
        <Typography color="secondary" display="inline" variant="h4">
          {name.toUpperCase()}
        </Typography>

        {currentUser && (
          <IconButton
            style={{ color: "gray", position: "relative", bottom: "10px" }}
            onClick={openEditForm}
          >
            <Edit />
          </IconButton>
        )}
        {currentUser && !product.featured && (
          <Tooltip title="set as featured" placement="top">
            <IconButton
              style={{ color: "gray", position: "relative", bottom: "10px" }}
              onClick={handleFeaturedToggle}
            >
              <StarOutline />
            </IconButton>
          </Tooltip>
        )}
        {currentUser && product.featured && (
          <Tooltip title="remove from featured" placement="top">
            <IconButton
              style={{ color: "gray", position: "relative", bottom: "10px" }}
              onClick={handleFeaturedToggle}
            >
              <Star />
            </IconButton>
          </Tooltip>
        )}

        {currentUser && product.hidden && (
          <Tooltip title="click to unhide" placement="top">
            <IconButton
              style={{ color: "gray", position: "relative", bottom: "10px" }}
              onClick={handleVisibilityToggle}
            >
              <VisibilityOff />
            </IconButton>
          </Tooltip>
        )}
        {currentUser && !product.hidden && (
          <Tooltip title="click to hide">
            <IconButton
              style={{ color: "gray", position: "relative", bottom: "10px" }}
              onClick={handleVisibilityToggle}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
        )}
        <Box className={classes.TagsContainer}>
          {currentUser &&
            tags.map((tag, ind) => (
              <Chip
                key={`tag-${ind}`}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
              />
            ))}
          {currentUser && (
            <Chip label="+ Add Tag" onClick={openTagForm} variant="outlined" />
          )}
        </Box>
        <Typography variant="h6">{description}</Typography>
        <Typography variant="subtitle1">{parse(longDescription)}</Typography>
        <br />
        {(product.hasFlavors || product.hasToppings) && (
          <Typography
            color="secondary"
            variant="h6"
            style={{ marginBottom: 10 }}
          >
            CUSTOMIZATIONS
          </Typography>
        )}
        {product.allowPersonalization && (
          <Box style={{ marginBottom: "10px", width: "310px" }}>
            <TextField
              variant="outlined"
              fullWidth
              label={product.personalizationPrompt}
              multiline
              minRows={3}
              onChange={handlePersonalization}
              value={personalization}
            />
          </Box>
        )}
        {product.hasFlavors && (
          <FormControl variant="outlined" className={selectStyle.formControl}>
            <InputLabel>Flavor</InputLabel>
            <Select label="Flavor" value={selFlavor} onChange={handleFlavor}>
              {product.flavors?.map((flavor, ind) => (
                <MenuItem key={`flavor${ind}`} value={flavor}>
                  {flavor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {product.hasToppings && (
          <FormControl variant="outlined" className={selectStyle.formControl}>
            <InputLabel>Topping</InputLabel>
            <Select label="Topping" value={selTopping} onChange={handleTopping}>
              {product.toppings?.map((topping, ind) => (
                <MenuItem key={`topping${ind}`} value={topping}>
                  {topping}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <br />
        {product.hasCoating && (
          <FormControl variant="outlined" className={selectStyle.coatingSelect}>
            <InputLabel>Coating</InputLabel>
            <Select label="Coating" value={selCoating} onChange={handleCoating}>
              {[
                "red",
                "bright pink",
                "light pink",
                "coral",
                "orange",
                "light orange",
                "light yellow",
                "yellow",
                "light green",
                "dark green",
                "teal",
                "light blue",
                "dark blue",
                "light purple",
                "dark purple",
                "bright white",
                "ivory",
                "milk chocolate",
                "dark chocolate",
                "black",
              ].map((coating, ind) => (
                <MenuItem key={`coating${ind}`} value={coating}>
                  <span
                    style={{
                      display: "inline-block",
                      backgroundColor: toHexCode(coating),
                      width: "20px",
                      height: "20px",

                      marginRight: "7px",
                      position: "relative",
                      top: "3px",
                    }}
                  ></span>
                  {coating}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <br />
        <br />
        <Typography color="secondary" variant="h6">
          QUANTITY
        </Typography>

        <Box width="120px">
          {minQuantity > 1 && (
            <Typography color="textSecondary">
              (minimum of {minQuantity})
            </Typography>
          )}
          <QuantityField
            quantity={quantity}
            minQuantity={product?.minQuantity}
            updateQuantity={updateQuantity}
          />
        </Box>

        <br />
        <Typography color="secondary" variant="h6">
          PRICE
        </Typography>
        <Typography variant="subtitle1">
          {"$" + (price * quantity).toFixed(2)}
        </Typography>
        <br />
        {addedToCart ? (
          <Typography color="secondary" variant="h6">
            Item added to cart!
          </Typography>
        ) : (
          <div style={{ height: "28px" }}></div>
        )}
        <Box width="200px">
          <Button
            variant="contained"
            color="primary"
            startIcon={<ShoppingCart />}
            fullWidth
            onClick={updateCart}
            disabled={detectChange(
              addedItem,
              quantity,
              selFlavor,
              selTopping,
              selCoating
            )}
          >
            {addedToCart ? "UPDATE CART" : "ADD TO ORDER"}
          </Button>
        </Box>
        <div style={{ height: "100px" }}></div>
      </Box>
    </Box>
  );
}

function detectChange(addedItem, quantity, selFlavor, selTopping, selCoating) {
  if (!addedItem) return false;
  if (quantity !== addedItem.quantity) {
    return false;
  } else if (addedItem.flavor && selFlavor !== addedItem.flavor) {
    return false;
  } else if (addedItem.topping && selTopping !== addedItem.topping) {
    return false;
  } else if (addedItem.coating && selCoating !== addedItem.coating) {
    return false;
  } else {
    return true;
  }
}
