import { Box, Backdrop, Fade, Modal, Typography } from "@material-ui/core";

export function Lightbox({ children, open, onClose }) {
  return (
    <Modal
      className="flex-center-all"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className="lightbox">{children}</Box>
      </Fade>
    </Modal>
  );
}

export function LightboxHeader({ title }) {
  return (
    <Typography variant="h5" style={{ marginBottom: "20px" }}>
      {title}
    </Typography>
  );
}
