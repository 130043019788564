import { useEffect } from "react";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import firebaseApp from "../../app/config/firebaseConfig.js";

export function useStorage(
  file,
  setFile,
  storagePath,
  setUploadProgress,
  makeRef
) {
  const storage = getStorage(firebaseApp);

  useEffect(() => {
    if (!file) return;
    const storageRef = ref(storage, storagePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get upload progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      // Handle unsuccessful uploads
      (error) => {
        console.log("an error occurred during image upload");
        console.log(error.message);
        setFile(null);
        setUploadProgress(null);
      },
      // Handle successful uploads on complete
      () => {
        makeRef();
        setFile(null);
        setUploadProgress(null);
      }
    );
    //eslint-disable-next-line
  }, [file]);
}
