import { createTheme } from "@material-ui/core/styles";

export const AdminPageTheme = createTheme({
  palette: {
    primary: {
      main: "#EFD042",
    },
    secondary: {
      main: "#BC9C10",
    },
  },
  typography: {
    fontFamily: "Lato",
    button: {
      fontSize: "0.8rem",
    },
  },
});

export const LandingPageTheme = createTheme({
  palette: {
    primary: {
      main: "#F3AF3F",
    },
    secondary: {
      main: "#B78851",
    },
    warning: {
      main: "#d32f2f",
    },
  },
  typography: {
    fontFamily: "Lato",
    button: {
      fontSize: "0.8rem",
    },
  },
});

export const loginTheme = createTheme({
  palette: {
    primary: {
      main: "#F3AF3F",
    },
    secondary: {
      main: "#B78851",
    },
  },
  typography: {
    fontFamily: "Lato",
    button: {
      fontSize: "0.8rem",
    },
  },
});

export const CookiePageTheme = createTheme({
  palette: {
    primary: {
      main: "#F3AF3F",
    },
    secondary: {
      main: "#B78851",
    },
  },
  typography: {
    fontFamily: "Lato",
    button: {
      fontSize: "0.8rem",
    },
  },
});

export const KitsPageTheme = createTheme({
  palette: {
    primary: {
      main: "#F3AF3F",
    },
    secondary: {
      main: "#B78851",
    },
  },
  typography: {
    fontFamily: "Lato",
    button: {
      fontSize: "0.8rem",
    },
  },
});

export const SpecialtyItemPageTheme = createTheme({
  palette: {
    primary: {
      main: "#F3AF3F",
    },
    secondary: {
      main: "#B78851",
    },
  },
  typography: {
    fontFamily: "Lato",
    button: {
      fontSize: "0.8rem",
    },
  },
});

export const SpecialEventsTheme = createTheme({
  palette: {
    primary: {
      main: "#F3AF3F",
    },
    secondary: {
      main: "#B78851",
    },
  },
  typography: {
    fontFamily: "Lato",
    button: {
      fontSize: "0.8rem",
    },
  },
});

export const NavBarTheme = createTheme({
  palette: {
    primary: {
      main: "#F3AF3F",
    },
    secondary: {
      main: "#B78851",
    },
  },
  typography: {
    fontFamily: "Lato",
    button: {
      fontSize: "0.8rem",
    },
  },
});
