import React, { useState } from "react";
import {
  Box,
  Badge,
  Tabs,
  Tab,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import { LandingPageTheme } from "../theme.js";
import CookiesPage from "./CookiesPage.jsx";
import FeaturedPage from "./FeaturedPage.jsx";
import KitsPage from "./KitsPage.jsx";
import RecipesPage from "./RecipesPage.jsx";
// import SetsAndDuosPage from "./SetsAndDuosPage.jsx";
import SpecialtyItemsPage from "./SpecialtyItemsPage.jsx";
import ShoppingCartDrawer from "./ShoppingCartDrawer.jsx";
import OrderReviewPage from "./OrderReviewPage.jsx";
import { ThemeProvider } from "@material-ui/core/styles";
import Footer from "./Footer.jsx";
import { useAuth } from "../app/contexts/AuthContext.js";
import CustomOrdersPage from "./CustomOrdersPage.jsx";
import { useHistory } from "react-router-dom";
import CuttersPage from "./CuttersPage.jsx";

export default function MainPage() {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [success, setSuccess] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { currentUser, logout } = useAuth();

  const updateTabIndex = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const [cartOpen, setCartOpen] = useState(false);

  const toggleCartOpen = (event) => {
    cartOpen ? setCartOpen(false) : setCartOpen(true);
  };

  function goToOrderDashboard() {
    history.push("/orders");
  }

  return (
    <ThemeProvider theme={LandingPageTheme}>
      <Box className="landscape-bar-decoration"></Box>
      <Box className="app-bar">
        <img
          style={{ marginLeft: "15px", marginTop: "4px", height: "80px" }}
          className="logo"
          src="https://storage.googleapis.com/easy-yolk.appspot.com/branding/easy_yolk_logo2.png"
          alt="easy yolk logo"
        />

        <Box height="100%" style={{ marginRight: "12px", marginTop: "13px" }}>
          <IconButton onClick={toggleCartOpen}>
            <Badge badgeContent={cartItems.length} color="secondary">
              <ShoppingCart />
            </Badge>
          </IconButton>
        </Box>
      </Box>

      <Tabs
        variant="scrollable"
        value={tabIndex}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={updateTabIndex}
        onClick={() => setSelectedItem(null)}
      >
        <Tab label="Home" />
        <Tab label="Custom" />
        <Tab label="Cookies" />
        {/* <Tab label="Sets & Duos" /> */}
        <Tab label="Kits" />
        <Tab label="Speciality Items" />
        <Tab label="Cutters" />

        {/* <Tab label="Special Events" /> */}

        {currentUser && <Tab label="Recipes" />}
      </Tabs>
      <Box className="floating-shopping-cart-btn">
        <IconButton onClick={toggleCartOpen}>
          <Badge badgeContent={cartItems.length}>
            <ShoppingCart />
          </Badge>
        </IconButton>
      </Box>
      <Box className="buffer"></Box>
      <Box className="overflow-y-only">
        {tabIndex === 0 && (
          <FeaturedPage
            setTabIndex={setTabIndex}
            setSelectedItem={setSelectedItem}
          />
        )}
        {tabIndex === 1 && (
          <CustomOrdersPage
            cartItems={cartItems}
            currentUser={currentUser}
            setCartItems={setCartItems}
          />
        )}
        {tabIndex === 2 && (
          <CookiesPage
            cartItems={cartItems}
            currentUser={currentUser}
            selectedCookie={selectedItem}
            setSelectedCookie={setSelectedItem}
            setCartItems={setCartItems}
          />
        )}
        {/* {tabIndex === 2 && (
          <SetsAndDuosPage
            cartItems={cartItems}
            currentUser={currentUser}
            selectedDuo={selectedItem}
            setSelectedDuo={setSelectedItem}
            setCartItems={setCartItems}
          />
        )} */}
        {tabIndex === 3 && (
          <KitsPage
            cartItems={cartItems}
            currentUser={currentUser}
            selectedKit={selectedItem}
            setSelectedKit={setSelectedItem}
            setCartItems={setCartItems}
          />
        )}
        {tabIndex === 4 && (
          <SpecialtyItemsPage
            cartItems={cartItems}
            currentUser={currentUser}
            selectedSpecialtyItem={selectedItem}
            setSelectedSpecialtyItem={setSelectedItem}
            setCartItems={setCartItems}
          />
        )}
        {tabIndex === 5 && (
          <CuttersPage
            cartItems={cartItems}
            currentUser={currentUser}
            selectedCutter={selectedItem}
            setSelectedCutter={setSelectedItem}
            setCartItems={setCartItems}
          />
        )}

        {tabIndex === 6 && <RecipesPage currentUser={currentUser} />}
        {/* {tabIndex === 6 && <SpecialEventsPage />} */}
        {tabIndex === 8 && (
          <OrderReviewPage
            cartItems={cartItems}
            currentUser={currentUser}
            success={success}
            setSuccess={setSuccess}
          />
        )}
      </Box>
      <Box width="100vw" className="footer">
        <Footer />
      </Box>

      {currentUser && (
        <Box
          className="absolute flex"
          style={{
            left: 0,
            bottom: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            alignItems: "center",
          }}
        >
          <Typography
            style={{ paddingLeft: "8px", paddingRight: "8px" }}
            color="secondary"
          >
            Welcome {currentUser.displayName || "guest"}!
          </Typography>
          <Button onClick={goToOrderDashboard} color="secondary">
            Pending Orders
          </Button>
          <Button onClick={logout} color="secondary">
            Logout
          </Button>
          {/* <UpdateNameForm displayName={currentUser?.displayName} /> */}
        </Box>
      )}

      <ShoppingCartDrawer
        cartItems={cartItems}
        cartOpen={cartOpen}
        setCartItems={setCartItems}
        toggleCartOpen={toggleCartOpen}
        setTabIndex={setTabIndex}
        success={success}
      />
    </ThemeProvider>
  );
}
