import { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import {
  fetchCarouselImgs,
  fetchFeaturedCookies,
  fetchFeaturedCutters,
  fetchFeaturedDuos,
  fetchFeaturedKits,
  fetchFeaturedSpecialtyItems,
} from "../app/firestoreClient";
import { FeaturedProductCard } from "./common/CustomCards";

export default function FeaturedPage({ setTabIndex, setSelectedItem }) {
  const [images, setImages] = useState([]);
  const [imgIndex, setImgIndex] = useState(0);
  const [featuredCookies, setFeaturedCookies] = useState([]);
  const [featuredDuos, setFeaturedDuos] = useState([]);
  const [featuredKits, setFeaturedKits] = useState([]);
  const [featuerdSpecialtyItems, setFeaturedSpecialtyItems] = useState([]);
  const [featuredCutters, setFeaturedCutters] = useState([]);

  const prevBtnStyle = {
    position: "absolute",
    left: 2,
    top: "42%",
    color: "whitesmoke",
    backgroundColor: "rgba(0,0,0,0.2)",
    zIndex: 10,
  };
  const nextBtnStyle = {
    position: "absolute",
    right: 2,
    top: "42%",
    color: "whitesmoke",
    backgroundColor: "rgba(0,0,0,0.2)",
    zIndex: 10,
  };

  function handlePrev() {
    setImgIndex(imgIndex === 0 ? images.length - 1 : imgIndex - 1);
  }

  function handleNext() {
    setImgIndex(imgIndex === images.length - 1 ? 0 : imgIndex + 1);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 6000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    const unsubscribe = fetchCarouselImgs(setImages);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = fetchFeaturedCookies(setFeaturedCookies);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = fetchFeaturedDuos(setFeaturedDuos);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = fetchFeaturedKits(setFeaturedKits);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = fetchFeaturedSpecialtyItems(setFeaturedSpecialtyItems);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = fetchFeaturedCutters(setFeaturedCutters);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  return (
    <Box style={{ height: "82vh" }}>
      <Typography style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
        Easy Yolk operates under a cottage food license through Orange County,
        CA. All products are made in a home kitchen.
      </Typography>
      <Typography style={{ paddingBottom: 20, paddingLeft: 20 }}>
        Permit #PR1229777.
      </Typography>
      <div className="relative">
        <IconButton style={prevBtnStyle} onClick={handlePrev}>
          <ChevronLeft />
        </IconButton>
        <IconButton style={nextBtnStyle} onClick={handleNext}>
          <ChevronRight />
        </IconButton>

        {images.map((data, ind) => (
          <div
            key={`image${ind}`}
            className={ind === imgIndex ? "slide active" : "slide"}
          >
            {ind === imgIndex && (
              <img
                className="image"
                src={data.url}
                alt=""
                height="300px"
                width="100%"
                style={{ objectFit: "cover" }}
              />
            )}
          </div>
        ))}
      </div>

      <Typography
        color="secondary"
        variant="h5"
        style={{ paddingTop: 10, paddingLeft: 10 }}
      >
        FEATURED ITEMS
      </Typography>
      <Box className="flex-row justify-center wrap">
        {featuredCookies.map((cookie) => (
          <FeaturedProductCard
            key={cookie.id}
            product={cookie}
            action={() => {
              setTabIndex(1);
              setSelectedItem(cookie);
            }}
          />
        ))}
        {featuredDuos.map((set) => (
          <FeaturedProductCard
            key={set.id}
            product={set}
            action={() => {
              setTabIndex(2);
              setSelectedItem(set);
            }}
          />
        ))}
        {featuredKits.map((kit) => (
          <FeaturedProductCard
            key={kit.id}
            product={kit}
            action={() => {
              setTabIndex(3);
              setSelectedItem(kit);
            }}
          />
        ))}
        {featuerdSpecialtyItems.map((specialtyItem) => (
          <FeaturedProductCard
            key={specialtyItem.id}
            product={specialtyItem}
            action={() => {
              setTabIndex(4);
              setSelectedItem(specialtyItem);
            }}
          />
        ))}
        {featuredCutters.map((cutter) => (
          <FeaturedProductCard
            key={cutter.id}
            product={cutter}
            action={() => {
              setTabIndex(5);
              setSelectedItem(cutter);
            }}
          />
        ))}
      </Box>
      <div style={{ height: "80px" }}></div>
    </Box>
  );
}
