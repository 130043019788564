import React, { useState } from "react";
import { addRecipe } from "../app/firestoreClient";
import { Lightbox, LightboxHeader } from "./common/Lightbox";
import { NameField } from "./CustomInputFields.jsx";
import { SubmitBtn } from "./common/CustomButtons.jsx";

export default function AddRecipeForm({ open, handleClose }) {
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");

  const values = {
    name: name,
    ingredients: [],
    steps: [],
  };

  const handleName = (e) => setName(e.target.value);

  function handleSubmit() {
    addRecipe(values, handleClose, setSubmitting);
  }

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="New Recipe" />
      <NameField onChange={handleName} value={name} />
      <SubmitBtn
        disabled={submitting}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Lightbox>
  );
}
