import {
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getFirestore,
  onSnapshot,
  query,
  updateDoc,
  orderBy,
  addDoc,
  arrayRemove,
  setDoc,
  serverTimestamp,
  where,
} from "firebase/firestore";
import firebaseApp from "./config/firebaseConfig";

const db = getFirestore(firebaseApp);
const carouselFirestoreRef = collection(db, "carousel");
const pendingOrdersRef = collection(db, "pendingOrders");
const fulfilledOrdersRef = collection(db, "fulfilledOrders");
const cookiesFirestoreRef = collection(db, "cookies");
const setsAndDuosFirestoreRef = collection(db, "sets_and_duos");
const customFirestoreRef = collection(db, "custom_cookies");
const kitsFirestoreRef = collection(db, "kits");
const specialtyItemsFirestoreRef = collection(db, "specialtyItems");
const cuttersFirestoreRef = collection(db, "cutters");
const recipesFirestoreRef = collection(db, "recipes");

export function deleteOrder(selOrder, setSelOrder) {
  deleteDoc(doc(db, "pendingOrders", selOrder.id)).then(() =>
    setSelOrder(null)
  );
}

export function moveToFulfilled(selOrder, setSelOrder) {
  const docRef = doc(db, "fulfilledOrders", selOrder.id);

  const updated = {
    ...selOrder,
    status: "fulfilled",
    dateFulfilled: serverTimestamp(),
  };

  setDoc(docRef, updated)
    .then(() => {
      deleteDoc(doc(db, "pendingOrders", selOrder.id));
      setSelOrder(updated);
    })
    .catch((err) => console.log(err));
}

export function moveToPending(selOrder, setSelOrder) {
  const docRef = doc(db, "pendingOrders", selOrder.id);

  const updated = {
    ...selOrder,
    status: "pending",
    dateFulfilled: deleteField(),
  };

  setDoc(docRef, updated, { merge: true })
    .then(() => {
      deleteDoc(doc(db, "fulfilledOrders", selOrder.id));
      setSelOrder(updated);
    })
    .catch((err) => console.log(err));
}

export function markConfirmed(selOrder, setSelOrder) {
  const docRef = doc(db, "pendingOrders", selOrder.id);

  const updated = {
    ...selOrder,
    status: "confirmed",
  };

  updateDoc(docRef, {
    status: "confirmed",
  }).then(() => setSelOrder(updated));
}

export function addProduct(colName, values, handleClose, setSubmitting) {
  const ref = collection(db, colName);
  setSubmitting(true);
  addDoc(ref, values)
    .then(() => setTimeout(() => handleClose(), 500))
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export function addKit(values, handleClose, setSubmitting) {
  const ref = collection(db, "kits");
  setSubmitting(true);
  addDoc(ref, values)
    .then(() => setTimeout(() => handleClose(), 500))
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export function addRecipe(values, handleClose, setSubmitting) {
  const ref = collection(db, "recipes");
  setSubmitting(true);
  addDoc(ref, values)
    .then(() => setTimeout(() => handleClose(), 500))
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export async function addSpecialtyItem(values, handleClose, setSubmitting) {
  const ref = collection(db, "specialtyItems");
  setSubmitting(true);
  addDoc(ref, values)
    .then(() => setTimeout(() => handleClose(), 500))
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export function addPendingOrder(values, setSubmitting, setSuccess) {
  const ref = collection(db, "pendingOrders");
  setSubmitting(true);
  addDoc(ref, {
    ...values,
    dateSubmitted: serverTimestamp(),
    status: "pending",
  })
    .then(() => setTimeout(() => setSuccess(true), 500))
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export function addOrderManually(values, setSubmitting, handleClose) {
  const ref = collection(db, "pendingOrders");
  setSubmitting(true);
  addDoc(ref, {
    ...values,
    dateSubmitted: serverTimestamp(),
    status: "pending",
  })
    .then(() => handleClose())
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export async function addProductPicURL(colName, product, file) {
  const ref = doc(db, colName, product.id);
  const { name } = file;

  const pictureURL = `https://storage.googleapis.com/easy-yolk.appspot.com/${colName}/${name}`;

  await updateDoc(ref, {
    pictures: arrayUnion(pictureURL),
  });
}

export async function addRecipePicURL(recipe, file) {
  const recipeDocRef = doc(db, "recipes", recipe.id);
  const { name } = file;

  const pictureURL = `https://storage.googleapis.com/easy-yolk.appspot.com/recipes/${name}`;

  await updateDoc(recipeDocRef, {
    pictures: arrayUnion(pictureURL),
  });
}

export async function addCustomCookieImg(file) {
  const { name, size } = file;

  const url = `https://storage.googleapis.com/easy-yolk.appspot.com/custom_cookies/${name}`;

  await addDoc(customFirestoreRef, {
    picture: { name: name, size: size, url: url, uploaded: serverTimestamp() },
    sortIndex: 99,
  });
}

export function fetchCarouselImgs(setImages) {
  const q = query(carouselFirestoreRef, orderBy("sortIndex", "asc"));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, ...doc.data() });
    });
    setImages(fetchedItems);
  });
}

export function fetchPendingOrders(setPendingOrders) {
  const q = query(pendingOrdersRef, orderBy("dateSubmitted", "desc"));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, status: "pending", ...doc.data() });
    });
    const pendingOrders = fetchedItems.filter((el) => el.status === "pending");
    setPendingOrders(pendingOrders);
  });
}

export function fetchConfirmedOrders(setConfirmedOrders) {
  const q = query(pendingOrdersRef, orderBy("dateSubmitted", "desc"));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, status: "confirmed", ...doc.data() });
    });
    const confirmedOrders = fetchedItems.filter(
      (el) => el.status === "confirmed"
    );
    setConfirmedOrders(confirmedOrders);
  });
}

export function fetchFulfilledOrders(setFulfilledOrders) {
  const q = query(fulfilledOrdersRef, orderBy("dateFulfilled", "desc"));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, status: "fulfilled", ...doc.data() });
    });
    setFulfilledOrders(fetchedItems);
  });
}

export function fetchFeaturedCookies(setFeaturedCookies) {
  const q = query(cookiesFirestoreRef, where("featured", "==", true));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({
        id: doc.id,
        ...doc.data(),
        redirectFromFeaturedPage: true,
      });
    });
    setFeaturedCookies(fetchedItems);
  });
}

export function fetchFeaturedDuos(setFeaturedDuos) {
  const q = query(setsAndDuosFirestoreRef, where("featured", "==", true));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({
        id: doc.id,
        ...doc.data(),
        redirectFromFeaturedPage: true,
      });
    });
    setFeaturedDuos(fetchedItems);
  });
}

export function fetchFeaturedKits(setFeaturedKits) {
  const q = query(kitsFirestoreRef, where("featured", "==", true));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({
        id: doc.id,
        ...doc.data(),
        redirectFromFeaturedPage: true,
      });
    });
    setFeaturedKits(fetchedItems);
  });
}

export function fetchFeaturedCutters(setFeaturedCutters) {
  const q = query(cuttersFirestoreRef, where("featured", "==", true));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({
        id: doc.id,
        ...doc.data(),
        redirectFromFeaturedPage: true,
      });
    });
    setFeaturedCutters(fetchedItems);
  });
}

export function fetchFeaturedSpecialtyItems(setSpecialtyItems) {
  const q = query(specialtyItemsFirestoreRef, where("featured", "==", true));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({
        id: doc.id,
        ...doc.data(),
        redirectFromFeaturedPage: true,
      });
    });
    setSpecialtyItems(fetchedItems);
  });
}

export function fetchCookies(setCookies) {
  const q = query(cookiesFirestoreRef, orderBy("sortIndex", "asc"));

  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, ...doc.data() });
    });
    setCookies(fetchedItems);
  });
}

export function fetchCutters(setCutters) {
  const q = query(cuttersFirestoreRef, orderBy("sortIndex", "asc"));

  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, ...doc.data() });
    });
    setCutters(fetchedItems);
  });
}

export function fetchSetsAndDuos(setSetsAndDuos) {
  const q = query(setsAndDuosFirestoreRef, orderBy("sortIndex", "asc"));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, ...doc.data() });
    });
    setSetsAndDuos(fetchedItems);
  });
}

export function fetchCustomCookies(setCookies) {
  const q = query(customFirestoreRef, orderBy("sortIndex", "asc"));

  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, ...doc.data() });
    });
    setCookies(fetchedItems);
  });
}

export function fetchKits(setKits) {
  const q = query(kitsFirestoreRef, orderBy("sortIndex", "asc"));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, ...doc.data() });
    });
    setKits(fetchedItems);
  });
}

export function fetchSpecialtyItems(setSpecialtyItems) {
  const q = query(specialtyItemsFirestoreRef, orderBy("sortIndex", "asc"));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, ...doc.data() });
    });
    setSpecialtyItems(fetchedItems);
  });
}

export function fetchRecipes(setRecipes) {
  const q = query(recipesFirestoreRef, orderBy("name", "asc"));
  onSnapshot(q, (querySnapshot) => {
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({
        id: doc.id,
        pictures: doc.data().pictures || [],
        ...doc.data(),
      });
    });
    setRecipes(fetchedItems);
  });
}

export function updateProductDetails(
  colName,
  product,
  values,
  handleClose,
  setSubmitting
) {
  const ref = doc(db, colName, product.id);
  setSubmitting(true);
  updateDoc(ref, values)
    .then(() => setTimeout(() => handleClose(), 500))
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export function updateRecipeDetails(
  recipe,
  values,
  handleClose,
  setSubmitting
) {
  const ref = doc(db, "recipes", recipe.id);
  setSubmitting(true);
  updateDoc(ref, values)
    .then(() => setTimeout(() => handleClose(), 500))
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export function updateTags(colName, docID, newTag, handleClose, setSubmitting) {
  const ref = doc(db, colName, docID);
  setSubmitting(true);
  updateDoc(ref, {
    tags: arrayUnion(newTag),
  })
    .then(() => setTimeout(() => handleClose(), 500))
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export function addCartItem(selOrder, values, handleClose, setSubmitting) {
  const ref = doc(db, "pendingOrders", selOrder.id);

  setSubmitting(true);
  updateDoc(ref, {
    cartItems: arrayUnion(values),
  })
    .then(() => handleClose())
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export function updateCartItems(selOrder, values, handleClose, setSubmitting) {
  const ref = doc(db, "pendingOrders", selOrder.id);
  setSubmitting(true);
  updateDoc(ref, {
    cartItems: values,
  })
    .then(() => handleClose())
    .catch((err) => console.log(err))
    .finally(() => setTimeout(() => setSubmitting(false), 300));
}

export function deleteTag(colName, docID, tag) {
  const ref = doc(db, colName, docID);

  updateDoc(ref, {
    tags: arrayRemove(tag),
  });
}

export async function deleteFirestoreRef(colName, product, url, setUrl) {
  const cookieDocRef = doc(db, colName, product.id);
  const { pictures } = product;

  const updatedPictures = pictures.filter((picture) => picture !== url);

  await updateDoc(cookieDocRef, {
    pictures: updatedPictures,
  }).then(() => setUrl(updatedPictures[0]));
}

export async function deleteRecipePicRef(
  selectedRecipe,
  selectedURL,
  setSelectedURL
) {
  const recipeDocRef = doc(db, "recipes", selectedRecipe.id);
  const { pictures } = selectedRecipe;

  const updatedPictures = pictures.filter((url) => url !== selectedURL);

  await updateDoc(recipeDocRef, {
    pictures: updatedPictures,
  }).then(() => setSelectedURL(updatedPictures[0]));
}

export async function deleteCustomCookie(selectedCookie) {
  const docRef = doc(db, "custom_cookies", selectedCookie.id);
  await deleteDoc(docRef);
}

export async function toggleFeatured(colName, selectedItem) {
  const docRef = doc(db, colName, selectedItem.id);
  const isFeatured = selectedItem.featured || false;
  await updateDoc(docRef, {
    featured: !isFeatured,
  });
}

export async function toggleVisibility(colName, selectedItem) {
  const docRef = doc(db, colName, selectedItem.id);
  const isHidden = selectedItem.hidden || false;
  await updateDoc(docRef, {
    hidden: !isHidden,
  });
}
