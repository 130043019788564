import React, { useEffect, useState } from "react";
import {
  DescriptionField,
  LongDescriptionField,
  MinQuantityField,
  NameField,
  PriceField,
} from "./CustomInputFields.jsx";
import { updateProductDetails } from "../app/firestoreClient.js";
import { Lightbox, LightboxHeader } from "./common/Lightbox.jsx";
import { SubmitBtn } from "./common/CustomButtons.jsx";
import {
  Box,
  Button,
  Chip,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

export default function EditProductForm({
  colName,
  handleClose,
  open,
  product,
  title,
}) {
  const zero = 0;
  const initVal = {
    id: product?.id || "",
    description: product?.description || "",
    longDescription: product?.longDescription || "",
    name: product?.name || "",
    minQuantity: product?.minQuantity || 1,
    price: product?.price?.toFixed(2) || zero.toFixed(2),
    hasFlavors: product?.hasFlavors || false,
    flavors: product?.flavors || [],
    hasToppings: product?.hasToppings || false,
    toppings: product?.toppings || [],
    allowPersonalization: product?.allowPersonalization || false,
    personalizationPrompt: product?.personalizationPrompt || "",
  };

  const [name, setName] = useState(initVal.name);
  const [description, setDescription] = useState(initVal.description);
  const [longDescription, setLongDescription] = useState(
    initVal.longDescription
  );

  const [minQuantity, setMinQuantity] = useState(initVal.minQuantity);
  const [price, setPrice] = useState(initVal.price);

  const [hasFlavors, setHasFlavors] = useState(initVal.hasFlavors);
  const [newFlavor, setNewFlavor] = useState("");
  const [flavors, setFlavors] = useState(initVal.flavors);
  const [hasToppings, setHasToppings] = useState(initVal.hasToppings);
  const [newTopping, setNewTopping] = useState("");
  const [toppings, setToppings] = useState(initVal.toppings);
  const [allowPersonalization, setAllowPersonalization] = useState(
    initVal.allowPersonalization
  );
  const [personalizationPrompt, setPersonalizationPrompt] = useState(
    initVal.personalizationPrompt
  );

  const [submitting, setSubmitting] = useState(false);

  const values = {
    description: description || "",
    longDescription: longDescription || "",
    name: name || "",
    minQuantity: parseFloat(minQuantity) || 1,
    price: parseFloat(price) || 0,
    allowPersonalization: allowPersonalization || false,
    personalizationPrompt: personalizationPrompt || "",
    hasFlavors: hasFlavors,
    flavors: flavors,
    hasToppings: hasToppings,
    toppings: toppings,
  };

  const handleName = (e) => setName(e.target.value);
  const handlePrice = (e) => setPrice(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);
  const handleLongDescription = (e) => setLongDescription(e.target.value);
  const handleMinQuantity = (e) => setMinQuantity(e.target.value);
  const handleHasFlavors = (e) => setHasFlavors(e.target.checked);
  const handleNewFlavor = (e) => setNewFlavor(e.target.value);
  const handleHasToppings = (e) => setHasToppings(e.target.checked);
  const handleNewTopping = (e) => setNewTopping(e.target.value);
  const handleAllowPersonalization = (e) =>
    setAllowPersonalization(e.target.checked);
  const handlePersonalizationPrompt = (e) =>
    setPersonalizationPrompt(e.target.value);

  function appendFlavor() {
    const updated = [...flavors, newFlavor];
    setFlavors(updated);
    setNewFlavor("");
  }

  function appendToppings() {
    const updated = [...toppings, newTopping];
    setToppings(updated);
    setNewTopping("");
  }

  function removeFlavor(ind) {
    const copy = [...flavors];
    copy.splice(ind, 1);
    setFlavors(copy);
  }

  function removeTopping(ind) {
    const copy = [...toppings];
    copy.splice(ind, 1);
    setToppings(copy);
  }

  function handleSubmit() {
    updateProductDetails(colName, product, values, handleClose, setSubmitting);
  }

  function resetForm() {
    setName(initVal.name);
    setDescription(initVal.description);
    setLongDescription(initVal.longDescription);
    setPrice(initVal.price);
    setAllowPersonalization(initVal.allowPersonalization);
    setPersonalizationPrompt(initVal.personalizationPrompt);
    setMinQuantity(initVal.minQuantity);
    setHasFlavors(initVal.hasFlavors);
    setFlavors(initVal.flavors);
    setHasToppings(initVal.hasToppings);
    setToppings(initVal.toppings);
  }

  useEffect(
    resetForm,
    //eslint-disable-next-line
    [open, product?.id]
  );

  return (
    <>
      <Lightbox open={open} onClose={handleClose}>
        <LightboxHeader title={title} />

        <NameField onChange={handleName} value={name} />
        <DescriptionField onChange={handleDescription} value={description} />
        <LongDescriptionField
          onChange={handleLongDescription}
          value={longDescription}
        />

        <Box width="100%" className="flex space-between">
          <MinQuantityField onChange={handleMinQuantity} value={minQuantity} />
          <PriceField onChange={handlePrice} value={price} />
        </Box>

        <Box style={{ display: "block", width: "100%" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allowPersonalization}
                onChange={handleAllowPersonalization}
                color="primary"
              />
            }
            label="Allow Personalization"
          />
        </Box>
        <TextField
          value={personalizationPrompt}
          onChange={handlePersonalizationPrompt}
          disabled={!allowPersonalization}
          fullWidth
          variant="filled"
          label="prompt"
          multiline
          minRows={2}
        />

        <Box
          style={{
            display: "block",
            width: "100%",
            paddingTop: 12,
            paddingBottom: 6,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={hasFlavors}
                onChange={handleHasFlavors}
                color="primary"
              />
            }
            label="Flavors"
          />
          <TextField
            value={newFlavor}
            onChange={handleNewFlavor}
            disabled={!hasFlavors}
            placeholder="new tag"
          />
          <Button onClick={appendFlavor} disabled={!hasFlavors}>
            Add
          </Button>
        </Box>
        <Box style={{ display: "block", width: "100%" }}>
          {flavors?.map((flavor, ind) => (
            <Chip
              key={`flavir${ind}`}
              disabled={!hasFlavors}
              label={flavor}
              style={{ padding: "5px", marginRight: "2px" }}
              onDelete={() => removeFlavor(ind)}
            />
          ))}
        </Box>

        <Box
          style={{
            display: "block",
            width: "100%",
            paddingTop: 12,
            paddingBottom: 6,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={hasToppings}
                onChange={handleHasToppings}
                color="primary"
              />
            }
            label="Toppings"
          />
          <TextField
            value={newTopping}
            onChange={handleNewTopping}
            disabled={!hasToppings}
            placeholder="new tag"
          />
          <Button onClick={appendToppings} disabled={!hasToppings}>
            Add
          </Button>
        </Box>

        <Box
          style={{
            display: "block",
            width: "100%",
          }}
        >
          {toppings?.map((topping, ind) => (
            <Chip
              disabled={!hasToppings}
              label={topping}
              onDelete={() => removeTopping(ind)}
              style={{ padding: "5px", marginRight: "2px" }}
            />
          ))}
        </Box>

        <SubmitBtn
          disabled={submitting}
          onSubmit={handleSubmit}
          submitting={submitting}
        />
      </Lightbox>
    </>
  );
}
