import React, { useState, useEffect } from "react";
import { Box, Button, Fab, Fade } from "@material-ui/core";
import { ArrowLeft, Add } from "@material-ui/icons";
import { CookiePageTheme } from "../theme.js";
import { ThemeProvider } from "@material-ui/core/styles";
import { fetchCutters } from "../app/firestoreClient.js";
import { ProductCard, ProductCardContainer } from "./common/CustomCards.jsx";
import { ProductDetails } from "./common/CustomProductCpnts.jsx";
import AddProductForm from "./AddProductForm.jsx";
import EditProductForm from "./EditProductForm.jsx";
import { NewTagForm } from "./NewTagForm.jsx";

export default function CuttersPage({
  cartItems,
  currentUser,
  selectedCutter,
  setSelectedCutter,
  setCartItems,
}) {
  const [cutters, setCutters] = useState([]);

  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [tagFormOpen, setTagFormOpen] = useState(false);

  const openAddForm = () => setAddFormOpen(true);
  const closeAddForm = () => setAddFormOpen(false);
  const openEditForm = () => setEditFormOpen(true);
  const closeEditForm = () => setEditFormOpen(false);
  const openTagForm = () => setTagFormOpen(true);
  const closeTagForm = () => setTagFormOpen(false);

  useEffect(() => {
    const unsubscribe = fetchCutters(setCutters);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedCutter?.redirectFromFeaturedPage) return;
    const updatedCutterInfo = cutters?.find(
      (cutter) => cutter.id === selectedCutter?.id
    );
    setSelectedCutter(updatedCutterInfo);
    //eslint-disable-next-line
  }, [cutters]);

  return (
    <ThemeProvider theme={CookiePageTheme}>
      {!selectedCutter && (
        <Fade in={!selectedCutter || false} timeout={1200}>
          <ProductCardContainer>
            {cutters.map((cutter) => (
              <ProductCard
                currentUser={currentUser}
                key={cutter.id}
                product={cutter}
                setSelected={setSelectedCutter}
              />
            ))}
          </ProductCardContainer>
        </Fade>
      )}

      {currentUser && !selectedCutter && (
        <Box className="floating-add-button">
          <Fab variant="extended" color="primary" onClick={openAddForm}>
            <Add /> CUTTER
          </Fab>
        </Box>
      )}

      {selectedCutter?.id && (
        <Box className="product-details-page">
          <Button
            onClick={() => setSelectedCutter(null)}
            startIcon={<ArrowLeft />}
          >
            All Cutters
          </Button>

          <ProductDetails
            cartItems={cartItems}
            colName="cutters"
            currentUser={currentUser}
            product={selectedCutter}
            openEditForm={openEditForm}
            openTagForm={openTagForm}
            setCartItems={setCartItems}
          />
        </Box>
      )}
      <AddProductForm
        colName="cutters"
        handleClose={closeAddForm}
        open={addFormOpen}
        title="New Cutter"
      />
      <EditProductForm
        colName="cutters"
        handleClose={closeEditForm}
        open={editFormOpen}
        product={selectedCutter}
        title="Cutter Details"
      />
      <NewTagForm
        colName="cutters"
        item={selectedCutter}
        open={tagFormOpen}
        handleClose={closeTagForm}
      />
    </ThemeProvider>
  );
}
