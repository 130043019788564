import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { OrderDetails } from "./common/OrderDetails";
import { addPendingOrder } from "../app/firestoreClient";
import { Alert } from "@material-ui/lab";
import { months } from "./common/lists";
import {
  getCurrentDate,
  getCurrentMonth,
  getDaysList,
} from "./common/dateUtils.js";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 130,
  },
  formControl2: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 80,
  },
}));

export default function OrderReviewPage({
  cartItems,
  currentUser,
  success,
  setSuccess,
}) {
  const [contactMethod, setContactMethod] = useState("text");
  const [receiveMethod, setReceiveMethod] = useState("pickup");
  const [termsAcknowledged, setTermsAcknowledged] = useState(false);
  const [selMonth, setSelMonth] = useState(getCurrentMonth());
  const [selDay, setSelDay] = useState(getCurrentDate());
  const [selYear, setSelYear] = useState("2024");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const values = {
    name: name,
    email: email,
    phoneNumber: phoneNumber,
    contactMethod: contactMethod,
    cartItems: cartItems,
    requestedDay: selDay,
    requestedMonth: selMonth,
    requestedYear: selYear,
    receiveMethod: receiveMethod,
    termsAcknowledged: termsAcknowledged,
  };

  const selectStyle = useStyles();

  const radioStyle = { position: "relative", bottom: "8px" };

  const daysList = getDaysList(selMonth);

  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhoneNumber = (e) => setPhoneNumber(e.target.value);
  const handleContactMethod = (e) => setContactMethod(e.target.value);
  const handleReceiveMethod = (e) => setReceiveMethod(e.target.value);
  const handleMonthSelect = (e) => setSelMonth(e.target.value);
  const handleDaySelect = (e) => setSelDay(e.target.value);
  const handleYearSelect = (e) => setSelYear(e.target.value);

  const handleTermsAcknowledged = (e) =>
    setTermsAcknowledged(!termsAcknowledged);

  return (
    <div className="review-order-container">
      <div className="review-order-section-container">
        <SectionHeader title="CART" />
        <OrderDetails cartItems={cartItems} />
      </div>

      <br />

      <div className="review-order-section-container">
        <SectionHeader title="CONTACT INFO" />
        <Typography>
          Please provide your contact information so we can confirm your order
        </Typography>
        <br />
        <TextField
          variant="outlined"
          label="name"
          required
          value={name}
          onChange={handleName}
        />
        <br />
        <br />
        <TextField
          variant="outlined"
          label="email"
          required
          value={email}
          onChange={handleEmail}
        />
        <br />
        <br />
        <TextField
          variant="outlined"
          label="phone number"
          placeholder="(XXX) XXX-XXXX"
          value={phoneNumber}
          onChange={handlePhoneNumber}
        />
        <br />
        <br />
        <br />

        <Typography style={{ fontStyle: "italic", marginBottom: "8px" }}>
          Preferred contact method?
        </Typography>

        <RadioGroup value={contactMethod} onChange={handleContactMethod}>
          <FormControlLabel
            value="text"
            control={<Radio color="primary" style={radioStyle} />}
            label="text"
            style={{ marginBottom: "8px", alignItems: "flex-start" }}
          />
          <FormControlLabel
            value="call"
            control={<Radio color="primary" style={radioStyle} />}
            label="call"
            style={{ marginBottom: "8px", alignItems: "flex-start" }}
          />
          <FormControlLabel
            value="email"
            control={<Radio color="primary" style={radioStyle} />}
            label="email"
            style={{ marginBottom: "8px", alignItems: "flex-start" }}
          />
        </RadioGroup>
        <br />
        <br />
        <Typography style={{ fontStyle: "italic", marginBottom: "8px" }}>
          How would you like to receive your order?
        </Typography>

        <RadioGroup value={receiveMethod} onChange={handleReceiveMethod}>
          <FormControlLabel
            value="pickup"
            control={<Radio color="primary" style={radioStyle} />}
            label="Pickup (free)"
            style={{ marginBottom: "8px", alignItems: "flex-start" }}
          />
          <FormControlLabel
            value="delivery"
            control={<Radio color="primary" style={radioStyle} />}
            label="Delivery (to Orange County, CA residents only, added fee based on distance, min $5)"
            style={{ marginBottom: "8px", alignItems: "flex-start" }}
          />
          <FormControlLabel
            value="shipped"
            control={<Radio color="primary" style={radioStyle} />}
            label="Shipped (3-5 day ground shipping, cost of postage will be included on final invoice)"
            style={{ marginBottom: "8px", alignItems: "flex-start" }}
          />
        </RadioGroup>
        <br />
        <br />
        <Typography style={{ fontStyle: "italic" }}>
          When would you like to receive your order?
        </Typography>
        <div className="flex align-center">
          <FormControl variant="outlined" className={selectStyle.formControl}>
            <InputLabel>Month</InputLabel>
            <Select label="Month" value={selMonth} onChange={handleMonthSelect}>
              {months.map((month, ind) => (
                <MenuItem key={`month${ind}`} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={selectStyle.formControl2}>
            <InputLabel>Day</InputLabel>
            <Select label="Day" value={selDay} onChange={handleDaySelect}>
              {daysList.map((day, ind) => (
                <MenuItem key={`day${ind}`} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={selectStyle.formControl}>
            <InputLabel>Year</InputLabel>
            <Select label="Year" value={selYear} onChange={handleYearSelect}>
              {["2024", "2025"].map((year, ind) => (
                <MenuItem key={`year${ind}`} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <br />
      <br />

      <div className="review-order-section-container">
        <SectionHeader title="ORDER FULFILLMENT AND PRICING ACKNOWLEDGMENT" />
        <Typography>
          After submitting this form, Easy Yolk will contact you to confirm
          whether the dates you requested are available, and to discuss any
          additional details needed to provide you with an accurate quote.
        </Typography>
        <FormControlLabel
          // style={{ alignItems: "flex-start" }}
          control={
            <Checkbox
              color="primary"
              checked={termsAcknowledged}
              onChange={handleTermsAcknowledged}
            />
          }
          label="I understand"
        />
      </div>
      <br />
      {success ? (
        <Alert>
          Thanks for your request! We sent you a confirmation email. We will
          reach out to you soon. If you have any questions, feel free to contact
          us at katie@easy-yolk.com or (949) 818-6591.
        </Alert>
      ) : (
        <Button
          onClick={() => addPendingOrder(values, setSubmitting, setSuccess)}
          disabled={!termsAcknowledged || !name || !email}
          variant="contained"
          color="primary"
          style={{ width: "150px" }}
        >
          {submitting ? (
            <CircularProgress size={25} style={{ color: "white" }} />
          ) : (
            "REQUEST QUOTE"
          )}
        </Button>
      )}

      <div style={{ height: "500px" }}></div>
    </div>
  );
}

function SectionHeader({ title }) {
  return (
    <Typography variant="h6" style={{ marginBottom: "8px" }}>
      {title}
    </Typography>
  );
}
