import React, { useState, useEffect } from "react";
import { addProduct } from "../app/firestoreClient.js";
import { Lightbox, LightboxHeader } from "./common/Lightbox.jsx";
import { NameField } from "./CustomInputFields.jsx";
import { SubmitBtn } from "./common/CustomButtons.jsx";

export default function AddProductForm({ colName, handleClose, open, title }) {
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");

  const values = {
    name: name,
    description: "",
    longDescription: "",
    pictures: [],
    price: 0,
    type: "cookie",
    tags: [],
    sortIndex: 99,
  };

  const handleName = (e) => setName(e.target.value);

  function handleSubmit() {
    addProduct(colName, values, handleClose, setSubmitting);
  }

  function resetForm() {
    setName("");
  }

  useEffect(resetForm, [open]);

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title={title} />
      <NameField onChange={handleName} value={name} />
      <SubmitBtn
        disabled={submitting}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Lightbox>
  );
}
