import React, { useState, useEffect } from "react";
import { Box, Button, Fab, Fade } from "@material-ui/core";
import { ArrowLeft, Add } from "@material-ui/icons";
import { CookiePageTheme } from "../theme.js";
import { ThemeProvider } from "@material-ui/core/styles";
import { fetchCookies } from "../app/firestoreClient.js";
import { ProductCard, ProductCardContainer } from "./common/CustomCards.jsx";
import { ProductDetails } from "./common/CustomProductCpnts.jsx";
import AddProductForm from "./AddProductForm.jsx";
import EditProductForm from "./EditProductForm.jsx";
import { NewTagForm } from "./NewTagForm.jsx";

export default function CookiesPage({
  cartItems,
  currentUser,
  selectedCookie,
  setSelectedCookie,
  setCartItems,
}) {
  const [cookies, setCookies] = useState([]);

  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [tagFormOpen, setTagFormOpen] = useState(false);

  const openAddForm = () => setAddFormOpen(true);
  const closeAddForm = () => setAddFormOpen(false);
  const openEditForm = () => setEditFormOpen(true);
  const closeEditForm = () => setEditFormOpen(false);
  const openTagForm = () => setTagFormOpen(true);
  const closeTagForm = () => setTagFormOpen(false);

  useEffect(() => {
    const unsubscribe = fetchCookies(setCookies);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedCookie?.redirectFromFeaturedPage) return;
    const updatedCookieInfo = cookies?.find(
      (cookie) => cookie.id === selectedCookie?.id
    );
    setSelectedCookie(updatedCookieInfo);
    //eslint-disable-next-line
  }, [cookies]);

  return (
    <ThemeProvider theme={CookiePageTheme}>
      {!selectedCookie && (
        <Fade in={!selectedCookie || false} timeout={1200}>
          <ProductCardContainer>
            {cookies.map((cookie) => (
              <ProductCard
                currentUser={currentUser}
                key={cookie.id}
                product={cookie}
                setSelected={setSelectedCookie}
              />
            ))}
          </ProductCardContainer>
        </Fade>
      )}

      {currentUser && !selectedCookie && (
        <Box className="floating-add-button">
          <Fab variant="extended" color="primary" onClick={openAddForm}>
            <Add /> COOKIE
          </Fab>
        </Box>
      )}

      {selectedCookie?.id && (
        <Box className="product-details-page">
          <Button
            onClick={() => setSelectedCookie(null)}
            startIcon={<ArrowLeft />}
          >
            All Cookies
          </Button>

          <ProductDetails
            cartItems={cartItems}
            colName="cookies"
            currentUser={currentUser}
            product={selectedCookie}
            openEditForm={openEditForm}
            openTagForm={openTagForm}
            setCartItems={setCartItems}
          />
        </Box>
      )}
      <AddProductForm
        colName="cookies"
        handleClose={closeAddForm}
        open={addFormOpen}
        title="New Cookie"
      />
      <EditProductForm
        colName="cookies"
        handleClose={closeEditForm}
        open={editFormOpen}
        product={selectedCookie}
        title="Cookie Details"
      />
      <NewTagForm
        colName="cookies"
        item={selectedCookie}
        open={tagFormOpen}
        handleClose={closeTagForm}
      />
    </ThemeProvider>
  );
}
