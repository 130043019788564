import { Button, CircularProgress } from "@material-ui/core";

export function SubmitBtn({ disabled, onSubmit, submitting }) {
  return (
    <Button
      style={{ marginTop: "15px" }}
      type="submit"
      variant="contained"
      color="primary"
      fullWidth
      disabled={disabled}
      onClick={onSubmit}
    >
      {submitting ? <CircularProgress color="primary" size={25} /> : "SAVE"}
    </Button>
  );
}

export function LoginBtn({ disabled, onSubmit, submitting }) {
  return (
    <Button
      style={{ marginTop: "5px" }}
      type="submit"
      variant="contained"
      color="primary"
      fullWidth
      disabled={disabled}
      onClick={onSubmit}
    >
      {submitting ? <CircularProgress color="primary" size={25} /> : "LOGIN"}
    </Button>
  );
}
