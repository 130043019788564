import { useState } from "react";
import { SubmitBtn } from "./common/CustomButtons";
import { Lightbox, LightboxHeader } from "./common/Lightbox";
import { TagField } from "./CustomInputFields";

import { updateTags } from "../app/firestoreClient";

export function NewTagForm({ item, colName, open, handleClose }) {
  const [tag, setTag] = useState("");
  const handleTag = (e) => setTag(e.target.value);
  const [submitting, setSubmitting] = useState(false);

  function handleSubmit() {
    updateTags(colName, item.id, tag, handleClose, setSubmitting);
  }

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Add Tag" />
      <TagField onChange={handleTag} value={tag} />

      <SubmitBtn
        submitting={submitting}
        disabled={submitting}
        onSubmit={handleSubmit}
      />
    </Lightbox>
  );
}
