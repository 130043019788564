import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
} from "@material-ui/core";

export function ProductCardContainer({ children }) {
  return (
    <Box className="product-cards-container">
      {children} <div style={{ height: "100px" }}></div>
    </Box>
  );
}

export function ProductCardFeaturedContainer({ children }) {
  return <Box className="product-cards-featured-container">{children}</Box>;
}

export function ProductCard({ currentUser, product, setSelected }) {
  const { name, description, pictures, id } = product;

  if (!currentUser && product.hidden) return null;

  if (currentUser && product.hidden)
    return (
      <Card key={id} className="product-card">
        <CardActionArea onClick={() => setSelected(product)}>
          <CardMedia
            className="product-img"
            style={{ opacity: 0.4 }}
            image={pictures[0] || ""}
          />
          <Typography
            style={{
              height: "30px",
              paddingLeft: "5px",
              paddingTop: "5px",
              opacity: 0.4,
            }}
            color="secondary"
            variant="subtitle1"
          >
            {name.toUpperCase() || "(no name)"}
          </Typography>
          <Typography
            style={{
              paddingLeft: "6px",
              paddingBottom: "6px",
              color: "gray",
              opacity: 0.4,
            }}
            variant="subtitle1"
          >
            {description || "(no description)"}
          </Typography>
        </CardActionArea>
      </Card>
    );

  return (
    <Card key={id} className="product-card">
      <CardActionArea onClick={() => setSelected(product)}>
        <CardMedia className="product-img" image={pictures[0] || ""} />
        <Typography
          style={{ height: "30px", paddingLeft: "5px", paddingTop: "5px" }}
          color="secondary"
          variant="subtitle1"
        >
          {name.toUpperCase() || "(no name)"}
        </Typography>
        <Typography
          style={{ paddingLeft: "6px", paddingBottom: "6px", color: "gray" }}
          variant="subtitle1"
        >
          {description || "(no description)"}
        </Typography>
      </CardActionArea>
    </Card>
  );
}

export function FeaturedProductCard({ product, action }) {
  const { name, description, pictures, id } = product;

  return (
    <Card key={id} className="product-card">
      <CardActionArea onClick={action}>
        <CardMedia className="product-img" image={pictures[0] || ""} />
        <Typography
          style={{ height: "30px", paddingLeft: "5px", paddingTop: "5px" }}
          color="secondary"
          variant="subtitle1"
        >
          {name.toUpperCase() || "(no name)"}
        </Typography>
        <Typography
          style={{ paddingLeft: "6px", paddingBottom: "6px", color: "gray" }}
          variant="subtitle1"
        >
          {description || "(no description)"}
        </Typography>
      </CardActionArea>
    </Card>
  );
}

export function CustomCookie({ product, setSelected }) {
  const { name, description, pictures, id } = product;

  return (
    <Card key={id} className="product-card">
      <CardActionArea onClick={() => setSelected(product)}>
        <CardMedia className="product-img" image={pictures[0] || ""} />
        <Typography
          style={{ height: "30px", paddingLeft: "5px", paddingTop: "5px" }}
          color="secondary"
          variant="h6"
        >
          {name || "(no name)"}
        </Typography>
        <Typography
          style={{ paddingLeft: "6px", paddingBottom: "6px", color: "gray" }}
          variant="subtitle1"
        >
          {description || "(no description)"}
        </Typography>
      </CardActionArea>
    </Card>
  );
}
