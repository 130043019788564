import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Fab,
  Fade,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, ArrowLeft, Clear } from "@material-ui/icons";
import { CookiePageTheme } from "../theme.js";
import { ThemeProvider } from "@material-ui/core/styles";
import AddRecipeForm from "./AddRecipeForm.jsx";
import EditRecipeForm from "./EditRecipeForm.jsx";
import { addRecipePicURL, fetchRecipes } from "../app/firestoreClient.js";
import { UploadImage } from "../app/utils/UploadFiles.jsx";
import { deleteRecipePic } from "../app/utils/deleteFromStorage.js";
import {
  ProductCard as RecipeCard,
  ProductCardContainer,
} from "./common/CustomCards.jsx";

export default function RecipesPage({ currentUser }) {
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const openAddForm = () => setAddFormOpen(true);
  const closeAddForm = () => setAddFormOpen(false);

  useEffect(() => {
    const unsubscribe = fetchRecipes(setRecipes);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updatedRecipeInfo = recipes?.find(
      (recipe) => recipe.id === selectedRecipe?.id
    );
    setSelectedRecipe(updatedRecipeInfo);
    //eslint-disable-next-line
  }, [recipes]);

  return (
    <ThemeProvider theme={CookiePageTheme}>
      {!selectedRecipe && (
        <Fade in={!selectedRecipe} timeout={1200}>
          <ProductCardContainer>
            {recipes.map((recipe) => (
              <RecipeCard
                key={recipe.id}
                product={recipe}
                setSelected={setSelectedRecipe}
              />
            ))}
          </ProductCardContainer>
        </Fade>
      )}

      {currentUser && !selectedRecipe && (
        <Box className="floating-add-button">
          <Fab variant="extended" color="primary" onClick={openAddForm}>
            <Add /> Recipe
          </Fab>
        </Box>
      )}
      <AddRecipeForm
        colName="recipes"
        open={addFormOpen}
        handleClose={closeAddForm}
        title="New Kit"
      />
      {selectedRecipe?.id && (
        <SelectedRecipeDetails
          selectedRecipe={selectedRecipe}
          setSelectedRecipe={setSelectedRecipe}
        />
      )}
    </ThemeProvider>
  );
}

function SelectedRecipeDetails({ selectedRecipe, setSelectedRecipe }) {
  const { name, pictures, ingredients, steps } = selectedRecipe;
  const [selectedURL, setSelectedURL] = useState(pictures[0]);
  const [scale, setScale] = useState(1);
  const [file, setFile] = useState(null);
  const picSelected = typeof selectedURL === "string";

  const handleScale = (e) => setScale(e.target.value);

  useEffect(() => {
    pictures?.length === 1 && setSelectedURL(pictures[0]);
    //eslint-disable-next-line
  }, [selectedRecipe]);

  if (!selectedRecipe) return null;

  return (
    <Box className="flex-row overflow-auto" style={{ height: "82vh" }}>
      <Box className="padding-x-medium relative " style={{ width: "400px" }}>
        <Box>
          <Button
            onClick={() => setSelectedRecipe(null)}
            startIcon={<ArrowLeft />}
          >
            All Recipes
          </Button>
        </Box>
        <Fade in={picSelected || false} timeout={1200}>
          <img width="350px" src={selectedURL} alt="selected cookie" />
        </Fade>
        {pictures?.length > 0 && (
          <IconButton
            style={{
              position: "absolute",
              top: "35px",
              left: "330px",
              backgroundColor: "rgba(0,0,0,0.2)",
              color: "silver",
            }}
            onClick={() =>
              deleteRecipePic(selectedRecipe, selectedURL, setSelectedURL)
            }
          >
            <Clear />
          </IconButton>
        )}
        {pictures.length < 1 && (
          <UploadImage
            file={file}
            setFile={setFile}
            storagePath={`recipes/${file?.name}`}
            makeRef={() => addRecipePicURL(selectedRecipe, file)}
          />
        )}
        <Typography variant="h4" color="secondary">
          {name}
        </Typography>
        <EditRecipeForm recipe={selectedRecipe} />
      </Box>

      <Box width="500px" style={{ paddingTop: "25px" }}>
        <Typography
          variant="h5"
          display="inline"
          style={{ paddingRight: "20px" }}
        >
          Ingredients
        </Typography>

        <TextField
          variant="outlined"
          type="number"
          value={scale}
          onChange={handleScale}
          textAlign="center"
          inputProps={{
            min: 1,
            max: 99,
            step: 0.5,
            style: { padding: 6, textAlign: "left", width: "45px" },
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">x</InputAdornment>,
          }}
          label="scale"
          color="secondary"
        />

        <table style={{ marginTop: "10px", paddingBottom: "60px" }}>
          <tbody>
            {ingredients?.map((ingredient, ind) => (
              <tr key={`ingredient-${ind}`}>
                <td
                  style={{
                    width: "40px",
                    textAlign: "right",
                  }}
                >
                  <Typography variant="subtitle1">
                    {parseAmount(ingredient.amount * scale)}
                  </Typography>
                </td>
                <td
                  style={{
                    width: "100px",
                    minWidth: "100px",
                    textAlign: "left",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography variant="subtitle1">
                    {abbreviate(ingredient.unit)}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">{ingredient.name}</Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>

      <Box width="500px">
        <Typography
          style={{ paddingTop: "25px", paddingBottom: "2px" }}
          variant="h5"
        >
          Instructions
        </Typography>
        <table style={{ marginTop: "10px", paddingBottom: "60px" }}>
          <tbody>
            {steps?.map((step, ind) => (
              <tr key={`step-${ind}`}>
                <td width="300px">
                  <Typography display="inline">{step}</Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <br />
      </Box>
    </Box>
  );
}

function parseAmount(amount) {
  const decimal = amount % 1;
  if (!decimal) return amount;

  const int = amount > 1 ? Math.trunc(amount) : "";

  switch (decimal) {
    case 0.125:
      return <Fraction int={int} num={1} den={8} />;
    case 0.25:
      return <Fraction int={int} num={1} den={4} />;
    case 0.375:
      return <Fraction int={int} num={3} den={8} />;
    case 0.5:
      return <Fraction int={int} num={1} den={2} />;
    case 0.625:
      return <Fraction int={int} num={5} den={8} />;
    case 0.75:
      return <Fraction int={int} num={3} den={4} />;
    case 0.875:
      return <Fraction int={int} num={7} den={8} />;
    default:
      return "error";
  }
}

function Fraction({ int, num, den }) {
  return (
    <>
      <span style={{ marginRight: "4px" }}>{int}</span>
      <sup>{num}</sup>&frasl;<sub>{den}</sub>
    </>
  );
}

function abbreviate(unit) {
  switch (unit) {
    case "teaspoons":
      return "tsp";
    case "tablespoons":
      return "Tbsp";
    case "ounces":
      return "oz";
    case "grams":
      return "g";
    default:
      return unit;
  }
}
