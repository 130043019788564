export function getFileExtension(file) {
  return file.name.split(".").pop();
}

export function makeID(length) {
  let randomID = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    randomID += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return randomID;
}

export function calculateTotal(cartItems) {
  if (cartItems.length === 0) return 0;

  const prices = cartItems.map((cartItem) => cartItem.price);
  const filteredPrices = prices.filter((el) => el);

  if (filteredPrices.length !== cartItems.length) return "TBD";

  const subtotals = cartItems.map(
    (cartItem) => cartItem.price * cartItem.quantity
  );

  const grandTotal = subtotals.reduce((acc, cur) => acc + cur);
  return grandTotal.toFixed(2);
}

export function toHexCode(colorName) {
  const colorNameNormalized = colorName.toLowerCase().trim();
  switch (colorNameNormalized) {
    case "red":
      return "#DD1B2A";
    case "bright pink":
      return "#ED1E79";
    case "light pink":
      return "#E08D94";
    case "coral":
      return "#EF4B3A";
    case "orange":
      return "#F2800F";
    case "light orange":
      return "#FBB03B";
    case "light yellow":
      return "#F9EA64";
    case "yellow":
      return "#FCEE21";
    case "light green":
      return "#8CC63F";
    case "dark green":
      return "#006837";
    case "teal":
      return "#00A99D";
    case "light blue":
      return "#29ABE2";
    case "dark blue":
      return "#2E3192";
    case "light purple":
      return "#B28CD3";
    case "dark purple":
      return "#662D91";
    case "bright white":
      return "#F7F4F2";
    case "ivory":
      return "#F2E4D3";
    case "milk chocolate":
      return "#754C24";
    case "dark chocolate":
      return "#42210B";
    case "black":
      return "#000000";
    default:
      return "white";
  }
}
