import React, { createContext, useContext, useEffect, useState } from "react";
import firebaseApp from "../config/firebaseConfig";

import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";

const auth = getAuth(firebaseApp);
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  // function resetPassword(email) {
  //   return auth.sendPasswordResetEmail(email);
  // }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function updateDisplayName(newDisplayName, handleClose, setSubmitting) {
    setSubmitting(true);
    updateProfile(currentUser, {
      displayName: newDisplayName,
    })
      .then(() =>
        setTimeout(() => {
          handleClose();
          console.log(newDisplayName);
        }, 500)
      )
      .catch((err) => console.log(err))
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  }

  // function updateEmail(email) {
  //   return currentUser.updateEmail(email);
  // }

  // function updateDisplayName(displayName) {
  //   return currentUser.updateProfile({
  //     displayName: displayName,
  //   });
  // }

  // function updateProfilePicture(imgURL) {
  //   return auth.updateProfile({
  //     imgURL: "https://example.com/jane-q-user/profile.jpg",
  //   });
  // }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    signup,
    updateDisplayName,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : null}
    </AuthContext.Provider>
  );
}
