import { days28, days30, days31, months } from "./lists";

export function getDaysList(selMonth) {
  switch (selMonth) {
    case "January":
    case "March":
    case "May":
    case "July":
    case "August":
    case "October":
    case "December":
      return days31;
    case "April":
    case "June":
    case "September":
    case "November":
      return days30;
    case "February":
      return days28;
    default:
      return days31;
  }
}

export function getCurrentMonth() {
  const currentDate = new Date();
  let monthIndex = currentDate.getMonth();

  const numDaysInMonth = getDaysList(months[monthIndex]).length;
  const dateNum = currentDate.getDate();
  let fiveDaysAhead = dateNum + 5;

  if (fiveDaysAhead > numDaysInMonth) monthIndex = monthIndex + 1;
  return months[monthIndex];
}

export function getCurrentDate() {
  const currentDate = new Date();
  const monthIndex = currentDate.getMonth();
  const dateNum = currentDate.getDate();

  let fiveDaysAhead = dateNum + 5;

  const numDaysInMonth = getDaysList(months[monthIndex]).length;

  if (fiveDaysAhead > numDaysInMonth)
    fiveDaysAhead = fiveDaysAhead % numDaysInMonth;

  return fiveDaysAhead.toString();
}
