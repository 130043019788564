import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { NameField } from "./CustomInputFields.jsx";
import { updateRecipeDetails } from "../app/firestoreClient.js";
import { Lightbox, LightboxHeader } from "./common/Lightbox.jsx";
import { SubmitBtn } from "./common/CustomButtons.jsx";

export default function EditCookieForm({ recipe }) {
  const initVal = {
    id: recipe.id,
    ingredients: recipe.ingredients || [],
    name: recipe.name || "",
    steps: recipe.steps || [],
  };

  const [open, setOpen] = useState(false);
  const [ingredients, setIngredients] = useState(initVal.ingredients);
  const [name, setName] = useState(initVal.name);
  const [steps, setSteps] = useState(initVal.steps);
  const [submitting, setSubmitting] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleName = (e) => setName(e.target.value);

  function handleStep(e, ind) {
    const stepsCopy = [...steps];
    stepsCopy[ind] = e.target.value;

    setSteps(stepsCopy);
  }

  function handleIngredientName(e, ind) {
    const ingredientsCopy = [...ingredients];
    ingredientsCopy[ind].name = e.target.value;
    setIngredients(ingredientsCopy);
  }

  function handleIngredientAmount(e, ind) {
    const ingredientsCopy = [...ingredients];
    ingredientsCopy[ind].amount = e.target.value;
    setIngredients(ingredientsCopy);
  }

  function handleIngredientUnit(e, ind) {
    const ingredientsCopy = [...ingredients];
    ingredientsCopy[ind].unit = e.target.value;
    setIngredients(ingredientsCopy);
  }

  const values = {
    ingredients: ingredients,
    name: name,
    steps: steps,
  };

  function addIngredient() {
    const newIngredient = { name: "", amount: 0, unit: "" };
    const updated = [...ingredients, newIngredient];
    setIngredients(updated);
  }

  function removeIngredient(ind) {
    const ingredientsCopy = [...ingredients];
    ingredientsCopy.splice(ind, 1);
    setIngredients(ingredientsCopy);
  }

  function addStep() {
    const updated = [...steps, ""];
    setSteps(updated);
  }

  function removeStep(ind) {
    const stepsCopy = [...steps];
    stepsCopy.splice(ind, 1);
    setSteps(stepsCopy);
  }

  function handleSubmit() {
    updateRecipeDetails(recipe, values, handleClose, setSubmitting);
  }

  return (
    <>
      <Button
        style={{ color: "gray" }}
        onClick={handleOpen}
        startIcon={<Edit />}
      >
        EDIT RECIPE
      </Button>
      <Lightbox open={open} onClose={handleClose}>
        <LightboxHeader title="Recipe Details" />

        <Box width="600px">
          <Box width="300px">
            <NameField onChange={handleName} value={name} />
          </Box>
          <Typography style={{ marginTop: "20px" }} variant="h6">
            Ingredients
          </Typography>
          {ingredients?.map((ingredient, ind) => (
            <Box
              key={`ingredient-${ind}`}
              className="flex-row padding-vertical-tiny"
            >
              <Box width="220px">
                <IngredientNameField
                  value={ingredient.name}
                  onChange={(e) => handleIngredientName(e, ind)}
                />
              </Box>
              <Box width="120px">
                <IngredientAmountField
                  value={ingredient.amount}
                  onChange={(e) => handleIngredientAmount(e, ind)}
                />
              </Box>
              <Box width="160px">
                <SelectUnitField
                  value={ingredient.unit}
                  onChange={(e) => handleIngredientUnit(e, ind)}
                />
              </Box>

              <Button onClick={() => removeIngredient(ind)}>remove</Button>
            </Box>
          ))}
          <Button onClick={addIngredient} startIcon={<Add />} color="secondary">
            ADD INGREDIENT
          </Button>
          <Typography style={{ marginTop: "20px" }} variant="h6">
            Instructions
          </Typography>

          {steps?.map((step, ind) => (
            <Box key={`step-${ind}`} className="flex-row padding-vertical-tiny">
              <Box width="500px">
                <StepsField onChange={(e) => handleStep(e, ind)} value={step} />
              </Box>

              <Button onClick={() => removeStep(ind)}>remove</Button>
            </Box>
          ))}
          <Button onClick={addStep} startIcon={<Add />} color="secondary">
            ADD STEP
          </Button>
        </Box>
        <SubmitBtn
          disabled={submitting}
          onSubmit={handleSubmit}
          submitting={submitting}
        />
      </Lightbox>
    </>
  );
}

const IngredientNameField = (props) => (
  <TextField
    color="secondary"
    variant="filled"
    label="ingredient"
    style={{ marginRight: "20px" }}
    {...props}
  />
);

const IngredientAmountField = (props) => (
  <TextField
    color="secondary"
    variant="filled"
    label="amount"
    type="number"
    inputProps={{ min: 0, max: 9999 }}
    style={{ marginRight: "20px" }}
    {...props}
  />
);

const StepsField = ({ value, onChange }) => (
  <TextField
    color="secondary"
    variant="filled"
    multiline
    rows={2}
    fullWidth
    value={value}
    onChange={onChange}
  />
);

const SelectUnitField = ({ value, onChange }) => (
  <Select
    fullWidth
    color="secondary"
    variant="outlined"
    style={{ marginRight: "20px" }}
    value={value}
    onChange={onChange}
  >
    <MenuItem value="cups">cups</MenuItem>
    <MenuItem value="tablespoons">tablespoons</MenuItem>
    <MenuItem value="teaspoons">teaspoons</MenuItem>
    <MenuItem value="grams">grams</MenuItem>
    <MenuItem value="ounces">ounces</MenuItem>
  </Select>
);
