import React from "react";
import { Box } from "@material-ui/core";
import { loginTheme } from "../theme.js";
import { ThemeProvider } from "@material-ui/core/styles";
import Login from "./user_authentication/Login.jsx";

export default function LoginPage() {
  return (
    <ThemeProvider theme={loginTheme}>
      <Box className="flex grow flex-center-all" height="100vh">
        <Box className=" column">
          <Box>
            <img
              width="280"
              src="https://storage.googleapis.com/easy-yolk.appspot.com/branding/easy_yolk_logo.png"
              alt="easy-yolk logo"
            />
          </Box>
          <Box>
            <Login />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
