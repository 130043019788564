import React, { useState, useEffect } from "react";
import { Box, Button, Fab, Fade } from "@material-ui/core";
import { Add, ArrowLeft } from "@material-ui/icons";
import { SpecialtyItemPageTheme } from "../theme.js";
import { ThemeProvider } from "@material-ui/core/styles";
import { fetchSpecialtyItems } from "../app/firestoreClient.js";
import { ProductCard, ProductCardContainer } from "./common/CustomCards.jsx";
import AddProductForm from "./AddProductForm.jsx";
import EditProductForm from "./EditProductForm.jsx";
import { ProductDetails } from "./common/CustomProductCpnts.jsx";
import { NewTagForm } from "./NewTagForm.jsx";

export default function SpecialtyItemsPage({
  cartItems,
  currentUser,
  selectedSpecialtyItem,
  setSelectedSpecialtyItem,
  setCartItems,
}) {
  const [specialtyItems, setSpecialtyItems] = useState([]);
  // const [selectedSpecialtyItem, setSelectedSpecialtyItem] = useState(null);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [tagFormOpen, setTagFormOpen] = useState(false);
  const openAddForm = () => setAddFormOpen(true);
  const closeAddForm = () => setAddFormOpen(false);
  const openEditForm = () => setEditFormOpen(true);
  const closeEditForm = () => setEditFormOpen(false);
  const openTagForm = () => setTagFormOpen(true);
  const closeTagForm = () => setTagFormOpen(false);

  useEffect(() => {
    const unsubscribe = fetchSpecialtyItems(setSpecialtyItems);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedSpecialtyItem?.redirectFromFeaturedPage) return;
    const updatedSpecialtyItemInfo = specialtyItems?.find(
      (specialtyItem) => specialtyItem.id === selectedSpecialtyItem?.id
    );
    setSelectedSpecialtyItem(updatedSpecialtyItemInfo);
    //eslint-disable-next-line
  }, [specialtyItems]);

  return (
    <ThemeProvider theme={SpecialtyItemPageTheme}>
      {!selectedSpecialtyItem && (
        <Fade in={!selectedSpecialtyItem || false} timeout={1200}>
          <ProductCardContainer>
            {specialtyItems.map((specialtyItem) => (
              <ProductCard
                currentUser={currentUser}
                key={specialtyItem.id}
                product={specialtyItem}
                setSelected={setSelectedSpecialtyItem}
              />
            ))}
          </ProductCardContainer>
        </Fade>
      )}

      {currentUser && !selectedSpecialtyItem && (
        <Box className="floating-add-button">
          <Fab variant="extended" color="primary" onClick={openAddForm}>
            <Add /> SPECIALTY ITEM
          </Fab>
        </Box>
      )}

      {selectedSpecialtyItem?.id && (
        <Box className="product-details-page">
          <Button
            onClick={() => setSelectedSpecialtyItem(null)}
            startIcon={<ArrowLeft />}
          >
            All Specialty Items
          </Button>
          <ProductDetails
            cartItems={cartItems}
            colName="specialtyItems"
            currentUser={currentUser}
            product={selectedSpecialtyItem}
            openEditForm={openEditForm}
            openTagForm={openTagForm}
            setCartItems={setCartItems}
          />
        </Box>
      )}
      <AddProductForm
        colName="specialtyItems"
        open={addFormOpen}
        handleClose={closeAddForm}
        title="New Specialty Item"
      />
      <EditProductForm
        colName="specialtyItems"
        handleClose={closeEditForm}
        open={editFormOpen}
        product={selectedSpecialtyItem}
        title="Specialty Item Details"
      />
      <NewTagForm
        colName="specialtyItems"
        item={selectedSpecialtyItem}
        open={tagFormOpen}
        handleClose={closeTagForm}
      />
    </ThemeProvider>
  );
}
