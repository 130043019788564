import "firebase/firestore";
import "firebase/storage";
import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
// import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCWqSt9PMamQLyiFY3cQaD72tdTsM66d2Y",
  authDomain: "easy-yolk.firebaseapp.com",
  projectId: "easy-yolk",
  storageBucket: "easy-yolk.appspot.com",
  messagingSenderId: "905216400358",
  appId: "1:905216400358:web:e58762dbec2c47b33f5427",
};

const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
