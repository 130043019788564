import {
  Avatar,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { calculateTotal } from "../app/utils/utils";

export default function ShoppingCartDrawer({
  cartItems,
  cartOpen,
  setCartItems,
  toggleCartOpen,
  setTabIndex,
  success,
}) {
  const cartItemIDs = cartItems.map((cartItem) => cartItem.id);
  const includesCustomOrder = cartItemIDs.includes("custom");

  return (
    <Drawer anchor="right" open={cartOpen} onClose={toggleCartOpen}>
      {cartItems.length > 0 && (
        <List>
          {cartItems.map((cartItem) => (
            <ListItem key={cartItem.id}>
              {cartItem.pictures && (
                <ListItemAvatar>
                  <Avatar src={cartItem.pictures[0]} />
                </ListItemAvatar>
              )}
              {cartItem.id === "custom" ? (
                <ListItemText
                  primary={`custom order (x${cartItem.quantity})`}
                />
              ) : (
                <ListItemText
                  primary={`${cartItem.name} (x${cartItem.quantity})`}
                  secondary={"$" + cartItem.price.toFixed(2) + " each"}
                />
              )}
            </ListItem>
          ))}

          <Divider />
          {includesCustomOrder ? (
            <ListItem>
              <ListItemText primary="price: TBD" />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemText
                primary={`TOTAL: $${calculateTotal(cartItems)}`}
                style={{ paddingLeft: "10px" }}
              />
            </ListItem>
          )}
          {!success && (
            <ListItem
              button
              onClick={() => {
                setCartItems([]);
                toggleCartOpen();
              }}
            >
              <ListItemText align="center" secondary="CLEAR CART" />
            </ListItem>
          )}
        </List>
      )}

      {cartItems.length === 0 && (
        <List>
          <ListItem>
            <ListItemText primary="nothing in your shopping cart yet" />
          </ListItem>
        </List>
      )}

      {success && (
        <List>
          <ListItem>
            <ListItemText
              align="center"
              primary="Your order has been submitted!"
              secondary="clear your cart to submit another order"
            />
          </ListItem>

          <ListItem
            button
            color="primary"
            onClick={() => {
              setCartItems([]);
              toggleCartOpen();
            }}
            style={{ backgroundColor: "#f3af3f" }}
          >
            <ListItemText align="center">CLEAR CART</ListItemText>
          </ListItem>
        </List>
      )}

      <div style={{ height: "10px" }}></div>

      {cartItems.length > 0 && !success && (
        <List>
          <ListItem
            button
            color="primary"
            onClick={() => {
              setTabIndex(8);
              toggleCartOpen();
            }}
            style={{ backgroundColor: success ? null : "#f3af3f" }}
          >
            <ListItemText align="center">REVIEW ORDER</ListItemText>
          </ListItem>
        </List>
      )}
    </Drawer>
  );
}
