import {
  Button,
  Card,
  CardActionArea,
  Typography,
  TextField,
  ThemeProvider,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useState, useEffect } from "react";

import {
  fetchPendingOrders,
  fetchFulfilledOrders,
  addOrderManually,
  deleteOrder,
  addCartItem,
  updateCartItems,
  fetchConfirmedOrders,
} from "../app/firestoreClient";
import { OrderDetails } from "./common/OrderDetails";
import { ArrowLeft, Add, Home } from "@material-ui/icons";
import { markConfirmed, moveToFulfilled } from "../app/firestoreClient";
import { useHistory } from "react-router-dom";
import { Lightbox, LightboxHeader } from "./common/Lightbox";
import { LandingPageTheme } from "../theme";
import { SubmitBtn } from "./common/CustomButtons";
import {
  NameField,
  EmailField,
  PriceField,
} from "../components/CustomInputFields.jsx";
import {
  getCurrentDate,
  getCurrentMonth,
  getDaysList,
} from "./common/dateUtils";
import { months } from "./common/lists";

export default function OrderDashboard() {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [confirmedOrders, setConfirmedOrders] = useState([]);
  const [fulfilledOrders, setFulfilledOrders] = useState([]);
  const [selOrder, setSelOrder] = useState(null);
  const [selItemIndex, setSelItemIndex] = useState(false);
  const [newOrderOpen, setNewOrderOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState("");
  const [newItemOpen, setNewItemOpen] = useState(false);
  const [editItemOpen, setEditItemOpen] = useState(false);

  const handleNewOrderOpen = () => setNewOrderOpen(true);
  const handleNewOrderClose = () => setNewOrderOpen(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);
  const handleNewItemOpen = () => setNewItemOpen(true);
  const handleNewItemClose = () => setNewItemOpen(false);
  const handleEditItemOpen = () => setEditItemOpen(true);
  const handleEditItemClose = () => setEditItemOpen(false);

  const handleConfirmDelete = (e) => setConfirmDelete(e.target.value);

  const handleDelete = (e) => {
    handleDeleteClose();
    deleteOrder(selOrder, setSelOrder);
  };

  const history = useHistory();
  const returnHome = () => history.push("/");

  useEffect(
    () =>
      setTimeout(() => {
        setConfirmDelete(false);
      }, 200),
    [deleteOpen]
  );

  useEffect(() => {
    const unsubscribe = fetchPendingOrders(setPendingOrders);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = fetchConfirmedOrders(setConfirmedOrders);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = fetchFulfilledOrders(setFulfilledOrders);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={LandingPageTheme}>
      <div style={{ height: "100vh", padding: 10 }} className="overflow-auto">
        <Typography variant="h6" display="inline">
          Order Dashboard
        </Typography>

        {!selOrder && (
          <>
            <div style={{ paddingLeft: "10px" }}>
              <Button startIcon={<Home />} onClick={returnHome}>
                Home
              </Button>

              <Button
                startIcon={<Add />}
                onClick={handleNewOrderOpen}
                style={{ marginLeft: "20px" }}
              >
                Add Order
              </Button>
            </div>
          </>
        )}

        <NewOrderForm open={newOrderOpen} handleClose={handleNewOrderClose} />

        {selOrder ? (
          <div>
            <Button onClick={() => setSelOrder(null)} startIcon={<ArrowLeft />}>
              All Orders
            </Button>
            <div style={{ padding: 30, paddingTop: 15 }}>
              <Typography variant="h6">Order {selOrder.id}</Typography>
              <br />
              <br />
              <Typography variant="h6" display="inline">
                Customer details
              </Typography>

              <Button
                onClick={() => markConfirmed(selOrder, setSelOrder)}
                style={{ position: "relative", bottom: 2, marginLeft: 5 }}
                disabled={
                  selOrder.status === "confirmed" ||
                  selOrder.status === "fulfilled"
                }
              >
                Mark Confirmed
              </Button>

              <Button
                onClick={() => moveToFulfilled(selOrder, setSelOrder)}
                style={{ position: "relative", bottom: 2, marginLeft: 5 }}
                disabled={selOrder.status === "fulfilled"}
              >
                Mark Fulfilled
              </Button>

              {selOrder.status !== "fulfilled" && (
                <Button
                  onClick={handleDeleteOpen}
                  style={{ position: "relative", bottom: 2, marginLeft: 5 }}
                >
                  Delete Order
                </Button>
              )}

              <Lightbox open={deleteOpen} onClose={handleDeleteClose}>
                <Typography>
                  Type "delete" in the field below to confirm
                </Typography>
                <br />
                <TextField
                  placeholder="delete"
                  variant="outlined"
                  color="secondary"
                  onChange={handleConfirmDelete}
                />
                <br />
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={confirmDelete !== "delete"}
                  onClick={handleDelete}
                >
                  DELETE ORDER
                </Button>
              </Lightbox>

              <CustomerDetails order={selOrder} />
              <br />
              <br />
              <Typography variant="h6">Fulfillment Details </Typography>
              <FulFillmentDetails order={selOrder} />
              <br />
              <br />
              <Typography display="inline" variant="h6">
                Cart Items
              </Typography>
              <Button
                onClick={handleNewItemOpen}
                startIcon={<Add />}
                style={{ position: "relative", bottom: 2, marginLeft: 20 }}
              >
                ADD ITEM
              </Button>
              <NewItemForm
                open={newItemOpen}
                handleClose={handleNewItemClose}
                selOrder={selOrder}
                setSelOrder={setSelOrder}
              />
              <EditItemForm
                open={editItemOpen}
                handleClose={handleEditItemClose}
                selItemIndex={selItemIndex}
                selOrder={selOrder}
                setSelOrder={setSelOrder}
              />
              <OrderDetails
                cartItems={selOrder.cartItems}
                dashboard
                handleOpen={handleEditItemOpen}
                setSelItemIndex={setSelItemIndex}
              />
            </div>
          </div>
        ) : (
          <div style={{ padding: 10 }}>
            {pendingOrders.map((order) => (
              <OrderSummaryCard
                key={order.id}
                order={order}
                setSelOrder={setSelOrder}
              />
            ))}
            {confirmedOrders.map((order) => (
              <OrderSummaryCard
                key={order.id}
                order={order}
                setSelOrder={setSelOrder}
              />
            ))}
            {fulfilledOrders.map((order) => (
              <OrderSummaryCard
                key={order.id}
                order={order}
                setSelOrder={setSelOrder}
              />
            ))}
          </div>
        )}

        <div style={{ height: "80px" }}></div>
      </div>
    </ThemeProvider>
  );
}

function OrderSummaryCard({ order, setSelOrder }) {
  const handleClick = () => setSelOrder(order);
  return (
    <Card style={{ marginTop: 5 }}>
      <CardActionArea style={{ padding: 10 }} onClick={handleClick}>
        <Typography>Order {order.id}</Typography>
        <CustomerDetails order={order} />
      </CardActionArea>
    </Card>
  );
}

function CustomerDetails({ order }) {
  return (
    <table style={{ marginLeft: "20px" }}>
      <tbody>
        <tr>
          <td style={{ width: "250px" }}>
            <Typography>status:</Typography>
          </td>
          <td>
            {(!order.status || order.status === "pending") && (
              <Typography style={{ color: "#B78851" }}>pending</Typography>
            )}
            {order.status === "confirmed" && (
              <Typography style={{ color: "#1fa8ed" }}>confirmed</Typography>
            )}
            {order.status === "fulfilled" && (
              <Typography style={{ color: "green" }}>{order.status}</Typography>
            )}
          </td>
        </tr>
        <Row header="name" info={order.name} />
        <Row header="email" info={order.email} />
        <Row header="phone number" info={order.phoneNumber} />
        <Row header="contact method" info={order.contactMethod} />
        <Row header="number of items" info={order.cartItems?.length} />
        <Row
          header="fulfillment date"
          info={`${order.requestedMonth} ${order.requestedDay}, ${order.requestedYear}`}
        />
      </tbody>
    </table>
  );
}

function FulFillmentDetails({ order }) {
  const requestedDate =
    order.requestedMonth +
    " " +
    order.requestedDay +
    ", " +
    order.requestedYear;
  return (
    <table style={{ marginLeft: "20px" }}>
      <tbody>
        <Row header="date requested" info={requestedDate} />
        <Row header="fulfillment method" info={order.receiveMethod} />
      </tbody>
    </table>
  );
}

function Row({ header, info }) {
  return (
    <tr>
      <td style={{ width: "250px" }}>
        <Typography>{header}:</Typography>
      </td>
      <td>
        <Typography>{info}</Typography>
      </td>
    </tr>
  );
}

const PhoneNumberField = ({ onChange, value }) => (
  <TextField
    color="secondary"
    label="phone number"
    variant="filled"
    fullWidth
    onChange={onChange}
    style={{ marginBottom: "5px" }}
    value={value}
  />
);

const QuantityField = ({ minQuantity, onChange, value }) => (
  <TextField
    color="secondary"
    label="quantity"
    inputProps={{ min: minQuantity, max: 99 }}
    style={{ marginLeft: "3%", width: "48%" }}
    onChange={onChange}
    step="1"
    type="number"
    value={value}
    variant="filled"
  />
);

const NoteField = ({ onChange, value }) => (
  <TextField
    label="note"
    fullWidth
    minRows={3}
    multiline
    onChange={onChange}
    value={value}
    color="secondary"
    variant="filled"
  />
);

function NewOrderForm({ open, handleClose }) {
  const radioStyle = { position: "relative", bottom: "8px" };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactMethod, setContactMethod] = useState("text");
  const [selMonth, setSelMonth] = useState(getCurrentMonth());
  const [selDay, setSelDay] = useState(getCurrentDate());
  const [selYear, setSelYear] = useState("2023");
  const [submitting, setSubmitting] = useState(false);

  const daysList = getDaysList(selMonth);

  const values = {
    name: name,
    email: email,
    phoneNumber: phoneNumber,
    contactMethod: contactMethod,
    cartItems: [],
    requestedDay: selDay,
    requestedMonth: selMonth,
    requestedYear: selYear,
    manuallyEntered: true,
  };

  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhoneNumber = (e) => setPhoneNumber(e.target.value);
  const handleContactMethod = (e) => setContactMethod(e.target.value);

  const handleMonthSelect = (e) => {
    setSelMonth(e.target.value);
  };

  const handleDaySelect = (e) => {
    setSelDay(e.target.value);
  };

  const handleYearSelect = (e) => {
    setSelYear(e.target.value);
  };

  const handleSubmit = (e) =>
    addOrderManually(values, setSubmitting, handleClose);

  function resetForm() {
    setName("");
    setPhoneNumber("");
    setEmail("");
    setSelDay(getCurrentDate());
    setSelMonth(getCurrentMonth());
    setSelYear("2023");
  }

  useEffect(() => setTimeout(() => resetForm(), 200), [open]);

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="New Order" />
      <NameField onChange={handleName} value={name} />
      <EmailField onChange={handleEmail} value={email} />
      <PhoneNumberField onChange={handlePhoneNumber} value={phoneNumber} />
      <br />
      <Typography>preferred contact method</Typography>
      <br />

      <RadioGroup value={contactMethod} onChange={handleContactMethod}>
        <FormControlLabel
          value="text"
          control={<Radio color="primary" style={radioStyle} />}
          label="text"
          style={{ alignItems: "flex-start" }}
        />
        <FormControlLabel
          value="call"
          control={<Radio color="primary" style={radioStyle} />}
          label="call"
          style={{ alignItems: "flex-start" }}
        />
        <FormControlLabel
          value="email"
          control={<Radio color="primary" style={radioStyle} />}
          label="email"
          style={{ alignItems: "flex-start" }}
        />
      </RadioGroup>
      <br />

      <Typography>fulfillment date</Typography>

      <br />

      <div className="flex align-center">
        <FormControl variant="outlined">
          <InputLabel>Month</InputLabel>
          <Select label="Month" value={selMonth} onChange={handleMonthSelect}>
            {months.map((month, ind) => (
              <MenuItem key={`month${ind}`} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Day</InputLabel>
          <Select
            label="Day"
            value={selDay}
            onChange={handleDaySelect}
            style={{ marginRight: "10px", marginLeft: "10px" }}
          >
            {daysList.map((day, ind) => (
              <MenuItem key={`day${ind}`} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Year</InputLabel>
          <Select label="Year" value={selYear} onChange={handleYearSelect}>
            {["2023", "2024"].map((year, ind) => (
              <MenuItem key={`year${ind}`} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <SubmitBtn
        disabled={submitting}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Lightbox>
  );
}

function NewItemForm({ open, handleClose, selOrder, setSelOrder }) {
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(Number.parseFloat(0).toFixed(2));
  const [note, setNote] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const newItem = {
    name: itemName,
    quantity: quantity,
    price: parseFloat(price) || 0,
    note: note,
  };

  const handleItemName = (e) => setItemName(e.target.value);
  const handlePrice = (e) => setPrice(e.target.value);
  const handleQuantity = (e) => setQuantity(e.target.value);
  const handleNote = (e) => setNote(e.target.value);
  const handleSubmit = (e) => {
    addCartItem(selOrder, newItem, handleClose, setSubmitting);
    refreshCartItems();
  };

  function refreshCartItems() {
    const updatedCartItems = [newItem, ...selOrder.cartItems];
    const updatedOrder = { ...selOrder, cartItems: updatedCartItems };

    setSelOrder(updatedOrder);
  }

  function resetItemForm() {
    setItemName("");
    setPrice(Number.parseFloat(0).toFixed(2));
    setQuantity(1);
    setNote("");
  }

  useEffect(() => setTimeout(() => resetItemForm(), 200), [open]);

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Add Item" />
      <NameField onChange={handleItemName} value={itemName} />

      <div style={{ width: "100%", marginBottom: "5px" }}>
        <PriceField onChange={handlePrice} value={price} />

        <QuantityField
          onChange={handleQuantity}
          value={quantity}
          minQuantity={1}
        />
      </div>
      <NoteField onChange={handleNote} value={note} />

      <SubmitBtn
        disabled={submitting}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Lightbox>
  );
}

function EditItemForm({
  open,
  handleClose,
  selItemIndex,
  selOrder,
  setSelOrder,
}) {
  const selItem = selOrder.cartItems[selItemIndex];

  const initVal = {
    name: selItem?.name || "",
    quantity: selItem?.quantity || 1,
    price:
      Number.parseFloat(selItem?.price).toFixed(2) ||
      Number.parseFloat(0).toFixed(2),
    note: selItem?.note || "",
  };

  const [itemName, setItemName] = useState(initVal.name);
  const [quantity, setQuantity] = useState(initVal.quantity);
  const [price, setPrice] = useState(initVal.price);
  const [note, setNote] = useState(initVal.note);
  const [submitting, setSubmitting] = useState(false);

  const handleItemName = (e) => setItemName(e.target.value);
  const handlePrice = (e) => setPrice(e.target.value);
  const handleQuantity = (e) => setQuantity(e.target.value);
  const handleNote = (e) => setNote(e.target.value);
  function handleSubmit(e) {
    const updatedItem = {
      ...selItem,
      name: itemName,
      quantity: quantity,
      price: parseFloat(price) || 0,
      note: note,
    };

    const updatedCartItems = JSON.parse(JSON.stringify(selOrder.cartItems));
    updatedCartItems[selItemIndex] = updatedItem;

    updateCartItems(selOrder, updatedCartItems, handleClose, setSubmitting);
    const updatedOrder = { ...selOrder, cartItems: updatedCartItems };
    setSelOrder(updatedOrder);
  }

  function resetItemForm() {
    setItemName(initVal.name);
    setPrice(initVal.price);
    setQuantity(initVal.quantity);
    setNote(initVal.note);
  }

  useEffect(
    () => setTimeout(() => resetItemForm(), 100),
    //eslint-disable-next-line
    [open]
  );

  return (
    <Lightbox open={open} onClose={handleClose}>
      <LightboxHeader title="Add Item" />
      <NameField onChange={handleItemName} value={itemName} />

      <div style={{ width: "100%", marginBottom: "5px" }}>
        <PriceField onChange={handlePrice} value={price} />

        <QuantityField
          onChange={handleQuantity}
          value={quantity}
          minQuantity={1}
        />
      </div>
      <NoteField onChange={handleNote} value={note} />

      <SubmitBtn
        disabled={submitting}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Lightbox>
  );
}
