import { Button } from "@material-ui/core";
import { calculateTotal, toHexCode } from "../../app/utils/utils";

export function OrderDetails({
  cartItems,
  dashboard,
  handleOpen,
  setSelItemIndex,
}) {
  return (
    <table style={{ paddingTop: "10px" }}>
      <tbody>
        {cartItems.map((item, ind) => (
          <tr key={`item${ind}`}>
            <td className="review-order-table-cell-img">
              <Picture item={item} />
            </td>
            <Cell>
              {item.id === "custom" ? (
                <>
                  <CellText> Custom Order (×{item.quantity})</CellText>
                  <br />
                  {item.occasion && (
                    <CellText style={{ color: "gray", display: "block" }}>
                      occasion:{" "}
                      <span style={{ fontStyle: "italic" }}>
                        {item.occasion}
                      </span>
                    </CellText>
                  )}
                  {item.note && (
                    <CellText style={{ color: "gray" }}>
                      themes:{" "}
                      <span style={{ fontStyle: "italic" }}>{item.note}</span>
                    </CellText>
                  )}
                </>
              ) : (
                <>
                  <CellText>{item.name + " (×" + item.quantity + ")"}</CellText>
                  <br />
                  {item.flavor && (
                    <CellText style={{ color: "gray" }}>
                      {item.flavor.toLowerCase()}
                    </CellText>
                  )}
                  {item.topping && (
                    <CellText style={{ color: "gray" }}>
                      {" "}
                      · {item.topping.toLowerCase()}{" "}
                    </CellText>
                  )}

                  {item.coating && (
                    <CellText style={{ color: "gray", whiteSpace: "nowrap" }}>
                      · <ColorSwatch color={item.coating} />
                      {item.coating}
                    </CellText>
                  )}
                  {item.personalization && (
                    <CellText style={{ color: "gray" }}>
                      personalization:{" "}
                      <span style={{ fontStyle: "italic" }}>
                        {item.personalization}
                      </span>
                    </CellText>
                  )}
                  {item.note && (
                    <CellText style={{ color: "gray", display: "block" }}>
                      note:{" "}
                      <span style={{ fontStyle: "italic" }}>{item.note}</span>
                    </CellText>
                  )}
                </>
              )}
            </Cell>

            <Cell>
              <CellText>{!item.price ? "TBD" : itemTotal(item)}</CellText>
            </Cell>
            {dashboard && (
              <Cell>
                <Button
                  onClick={() => {
                    handleOpen();
                    setSelItemIndex(ind);
                  }}
                >
                  EDIT
                </Button>
              </Cell>
            )}
          </tr>
        ))}
        <div style={{ height: "5px" }}></div>

        <tr>
          <Cell>
            <CellTextStrong></CellTextStrong>
          </Cell>
          <Cell>
            <CellTextStrong>Estimated Subtotal</CellTextStrong>
          </Cell>
          <Cell>
            <CellTextStrong>{calculateTotal(cartItems)}</CellTextStrong>
          </Cell>
          {dashboard && (
            <Cell>
              <CellTextStrong></CellTextStrong>
            </Cell>
          )}
        </tr>
      </tbody>
    </table>
  );
}

function Cell({ children }) {
  return <td className="review-order-table-cell">{children}</td>;
}

function CellText({ children, ...props }) {
  return (
    <span className="review-order-table-typography" style={props.style}>
      {children}
    </span>
  );
}

function CellTextStrong({ children }) {
  return (
    <span className="review-order-table-typography-est-total">{children}</span>
  );
}

function itemTotal(item) {
  const total = (item.price * item.quantity).toFixed(2);
  return "$" + total;
}

function ColorSwatch({ color }) {
  return (
    <span
      style={{
        display: "inline-block",
        backgroundColor: toHexCode(color),
        width: "15px",
        height: "15px",
        marginLeft: "5px",
        marginRight: "5px",
        position: "relative",
        top: "1px",
      }}
    ></span>
  );
}

function Picture({ item }) {
  if (!item.pictures) return null;

  return (
    <img src={item.pictures[0]} alt="" className="review-order-table-img" />
  );
}
