import React, { useState } from "react";
import { Box } from "@material-ui/core";
// import SignUpForm from "../user_authentication/SignUpForm.jsx";
// import ResetPasswordForm from "../user_authentication/ResetPasswordForm.jsx";
import { useAuth } from "../../app/contexts/AuthContext.js";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { LoginBtn } from "../common/CustomButtons.jsx";
import { EmailField, PasswordField } from "../CustomInputFields.jsx";

export default function Login() {
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState("");
  const history = useHistory();

  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  function handleSubmit() {
    setSubmitting(true);

    login(email, password)
      .then(() => history.push("/"))
      .catch((error) => {
        setLoginStatus("failure");
        setErrorMessage(error.message);
      })
      .finally(() => setSubmitting(false));
  }

  function handleKeyPress(e) {
    if (e.code !== "Enter") {
      return;
    } else {
      handleSubmit();
    }
  }

  return (
    <Box
      className="flex-column flex-center-all"
      width="300px"
      onKeyPress={handleKeyPress}
    >
      {loginStatus === "failure" && (
        <Box width="100%" mb="5px">
          <Alert severity="warning" fullWidth>
            {errorMessage}
          </Alert>
        </Box>
      )}
      <EmailField onChange={handleEmail} value={email} />
      <PasswordField onChange={handlePassword} value={password} />

      <LoginBtn
        disabled={submitting}
        onSubmit={handleSubmit}
        submitting={submitting}
      />

      {/* <Box>
        <ResetPasswordForm />
      </Box> */}
      {/* <Box display="flex" alignItems="center">
        <Typography variant="subtitle2">Need an account?</Typography>
        <SignUpForm />
      </Box> */}
    </Box>
  );
}
