import { useState } from "react";
import { Box, Button, LinearProgress, Typography } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useStorage } from "../../app/hooks/useStorage.js";
import { getFileExtension } from "./utils.js";

export function UploadImage({ file, setFile, storagePath, makeRef }) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(null);

  useStorage(file, setFile, storagePath, setUploadProgress, makeRef);

  const acceptedTypes = ["image/png", "image/jpeg"];
  const acceptedExtensions = ["PNG", "jpeg"];

  const handleSelectFile = (e) => {
    let selectedFile = e.target.files[0];
    const fileType = selectedFile?.type;
    const fileExtension = getFileExtension(selectedFile);

    const validFile =
      selectedFile &&
      (acceptedTypes.includes(fileType) ||
        acceptedExtensions.includes(fileExtension));

    if (validFile) {
      setFile(selectedFile);
      setError(false);
      setErrorMessage("");
    } else {
      setFile(null);
      setError(true);
      setErrorMessage("Please select an image file (.jpeg or .png)");
    }
  };

  return (
    <Box>
      <form autoComplete="off">
        <Button
          color="primary"
          component="label"
          variant="contained"
          startIcon={<CloudUpload />}
        >
          Upload Pictures
          <input type="file" hidden onChange={handleSelectFile} />
        </Button>
      </form>

      {error && <Alert severity="warning">{errorMessage}</Alert>}
      {file && <Typography>{file.name}</Typography>}
      {uploadProgress > 0 && (
        <LinearProgress variant="determinate" value={uploadProgress} />
      )}
    </Box>
  );
}
