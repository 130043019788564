import {
  deleteFirestoreRef,
  deleteRecipePicRef,
  deleteCustomCookie,
} from "../firestoreClient.js";
import { getStorage, ref, deleteObject } from "firebase/storage";
import firebaseApp from "../../app/config/firebaseConfig";

export function deleteProductPic(colName, product, url, setUrl) {
  const storage = getStorage(firebaseApp);
  const pictureRef = ref(storage, url);
  deleteObject(pictureRef)
    .then(() => deleteFirestoreRef(colName, product, url, setUrl))
    .catch((error) => console.log(error));
}

export async function deleteRecipePic(
  selectedRecipe,
  selectedURL,
  setSelectedURL
) {
  const storage = getStorage(firebaseApp);
  const pictureRef = ref(storage, selectedURL);
  deleteObject(pictureRef)
    .then(() => deleteRecipePicRef(selectedRecipe, selectedURL, setSelectedURL))
    .catch((error) => {
      console.log("an error occured while trying to delete the image");
    });
}

export async function deleteCustomPic(cookie) {
  const storage = getStorage(firebaseApp);
  const pictureRef = ref(storage, cookie.picture.url);
  deleteObject(pictureRef).then(() => deleteCustomCookie(cookie));
}
