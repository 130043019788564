import {
  Box,
  Button,
  Divider,
  Fade,
  Modal,
  Backdrop,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  addCustomCookieImg,
  fetchCustomCookies,
} from "../app/firestoreClient.js";
import { UploadImage } from "../app/utils/UploadFiles.jsx";
import { deleteCustomPic } from "../app/utils/deleteFromStorage.js";
import { useState, useEffect, useRef } from "react";
import { ShoppingCart } from "@material-ui/icons";
import { ArrowDropDown } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
}));

export default function CustomOrdersPage({
  cartItems,
  currentUser,
  setCartItems,
}) {
  const [imgPrevOpen, setImgPrevOpen] = useState(false);
  const [selImg, setSelImg] = useState(null);
  const [itemType, setItemType] = useState("decorated cookies");
  const [occasion, setOccasion] = useState("");
  const [note, setNote] = useState("");
  const [quantity, setQuantity] = useState(6);
  const cartItemIDs = cartItems.map((cartItem) => cartItem.id);
  const addedToCart = cartItemIDs.includes("custom");

  const galleryRef = useRef(null);

  const handleItemType = (e) => {
    setItemType(e.target.value);
  };

  const handleQuantity = (e) => {
    setQuantity(e.target.value);
  };

  const handleOccasion = (e) => {
    setOccasion(e.target.value);
  };

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  const openImgPrev = () => setImgPrevOpen(true);
  const closeImgPrev = () => setImgPrevOpen(false);

  const selectStyle = useStyles();

  const customOrderDetails = {
    id: "custom",
    name: "Custom Order",
    itemType: itemType,
    occasion: occasion,
    quantity: quantity,
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/easy-yolk.appspot.com/o/custom_cookies%2FCustom%20Item%20Photo-01.png?alt=media&token=8a6d7d13-3a02-455c-a29a-8fa5bc8d5a00",
    ],

    note: note,
  };

  function updateCart() {
    const updatedItems = cartItems.map((cartItem) => {
      if (cartItem.id === "custom") return customOrderDetails;
      else return cartItem;
    });

    !addedToCart && setCartItems([...cartItems, customOrderDetails]);
    addedToCart && setCartItems(updatedItems);
  }

  function scrollToGallery() {
    galleryRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div style={{ height: "84vh" }}>
      <div style={{ padding: 20 }}>
        <Typography
          variant="h5"
          color="secondary"
          display="inline"
          style={{ marginRight: 15 }}
        >
          Custom Order Request
        </Typography>
        <Button
          onClick={scrollToGallery}
          startIcon={<ArrowDropDown />}
          style={{
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            position: "relative",
            bottom: 3,
          }}
        >
          Gallery
        </Button>
        <Typography color="secondary">
          Have a special event? Easy Yolk offers custom decorated cookies, cake
          pops, or party favors tailored to your occasion.
        </Typography>
        <br />
        <br />
        <Typography style={{ fontStyle: "italic" }}>
          What's the requested item and quantity?
        </Typography>
        <br />
        <FormControl variant="outlined" className={selectStyle.formControl}>
          <InputLabel>Item</InputLabel>
          <Select label="Item" value={itemType} onChange={handleItemType}>
            {[
              "decorated cookies",
              "cookie kits",
              "cake pops",
              "cocoa bombs",
              "other",
            ].map((itemType, ind) => (
              <MenuItem key={`itemType${ind}`} value={itemType}>
                {itemType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          inputProps={{ min: 1, max: 500, step: 1 }}
          label="Quantity"
          onChange={handleQuantity}
          style={{ marginLeft: "10px", width: "100px" }}
          type="number"
          value={quantity}
          variant="outlined"
        />
        <br />
        <br />
        <br />
        <Typography style={{ fontStyle: "italic" }}>
          What's the occassion?
        </Typography>
        <br />
        <TextField
          fullWidth
          placeholder="examples: birthday, graduation, retirement party, baby shower"
          onChange={handleOccasion}
          value={occasion}
          variant="outlined"
        />
        <br />
        <br />
        <br />
        <Typography style={{ fontStyle: "italic" }}>
          What themes, colors, and imagery are part of the occassion?
        </Typography>
        <TextField
          fullWidth
          minRows={2}
          multiline
          onChange={handleNote}
          value={note}
          variant="outlined"
        />
        <br />
        <br />
        <Box width="200px">
          <Button
            variant="contained"
            color="primary"
            startIcon={<ShoppingCart />}
            fullWidth
            onClick={updateCart}
          >
            {addedToCart ? "UPDATE CART" : "ADD TO ORDER"}
          </Button>
        </Box>
      </div>
      <br />

      <Divider />
      <br />
      <br />

      <Typography
        ref={galleryRef}
        color="secondary"
        variant="h5"
        style={{ paddingLeft: "20px" }}
      >
        Gallery
      </Typography>
      <br />

      <Gallery
        currentUser={currentUser}
        setSelImg={setSelImg}
        openImgPrev={openImgPrev}
      />
      <div style={{ height: "50px" }}></div>

      <ImagePreview open={imgPrevOpen} onClose={closeImgPrev} selImg={selImg} />
    </div>
  );
}

function Gallery({ currentUser, setSelImg, openImgPrev }) {
  const [file, setFile] = useState(null);
  const [customCookies, setCustomCookies] = useState([]);

  const galleryImgStyle = {
    width: "100%",
  };

  useEffect(() => {
    const unsubscribe = fetchCustomCookies(setCustomCookies);
    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-row wrap justify-center">
      {customCookies.map((cookie) => (
        <div
          className="relative"
          key={cookie.id}
          style={{
            width: "300px",
            maxWidth: "42vw",
            padding: "5px",
          }}
        >
          <img
            id={cookie.id}
            className="hover-pointer"
            src={cookie.picture?.url}
            alt=""
            style={galleryImgStyle}
            onClick={() => {
              setSelImg(cookie);
              openImgPrev();
            }}
          />
          {currentUser && (
            <Button
              onClick={() => deleteCustomPic(cookie)}
              style={{
                position: "absolute",
                bottom: 10,
                right: 8,
                backgroundColor: "rgba(255,255,255,0.7)",
              }}
            >
              delete
            </Button>
          )}
        </div>
      ))}

      {currentUser && (
        <UploadImage
          file={file}
          setFile={setFile}
          storagePath={`custom_cookies/${file?.name}`}
          makeRef={() => addCustomCookieImg(file)}
        />
      )}
    </div>
  );
}

function ImagePreview({ open, onClose, selImg }) {
  if (!selImg) return null;
  return (
    <Modal
      className="flex-center-all"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <img
          src={selImg.picture?.url}
          style={{ maxHeight: "80%", maxWidth: "90%" }}
          alt=""
        />
      </Fade>
    </Modal>
  );
}
