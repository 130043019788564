import { InputAdornment, TextField, makeStyles } from "@material-ui/core";

export const DescriptionField = (props) => (
  <TextField
    color="secondary"
    fullWidth
    label="description"
    multiline
    rows={2}
    style={{ marginBottom: 5 }}
    variant="filled"
    {...props}
  />
);

export const LongDescriptionField = (props) => (
  <TextField
    color="secondary"
    fullWidth
    label="long description"
    variant="filled"
    multiline
    rows={5}
    style={{ marginBottom: 5 }}
    {...props}
  />
);

export const NameField = ({ onChange, value }) => (
  <TextField
    color="secondary"
    fullWidth
    label="name"
    onChange={onChange}
    style={{ marginBottom: 5 }}
    value={value}
    variant="filled"
  />
);

export const MinQuantityField = ({ onChange, value }) => (
  <TextField
    color="secondary"
    label="min quantity"
    inputProps={{ min: 1, max: 50, step: 1 }}
    onChange={onChange}
    style={{ width: "49%" }}
    type="number"
    value={value}
    variant="filled"
  />
);

export const PriceField = ({ onChange, value }) => (
  <TextField
    color="secondary"
    label="price"
    inputProps={{ min: 0, max: 99, step: 0.01 }}
    InputProps={{
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }}
    onChange={onChange}
    style={{ width: "49%" }}
    type="number"
    value={value}
    variant="filled"
  />
);

export const TagField = (props) => (
  <TextField
    color="secondary"
    fullWidth
    label="tag"
    style={{ marginBottom: 5 }}
    variant="filled"
    {...props}
  />
);

export const TagsStyling = makeStyles((theme) => ({
  TagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export const QuantityField = ({ quantity, minQuantity, updateQuantity }) => (
  <TextField
    fullWidth
    inputProps={{ min: minQuantity, max: 99, style: { padding: 5 } }}
    onChange={updateQuantity}
    step="1"
    style={{ marginBottom: "10px", marginTop: "5px" }}
    type="number"
    value={quantity}
    variant="outlined"
  />
);

export const EmailField = ({ onChange, value }) => (
  <TextField
    autoComplete="off"
    color="secondary"
    label="email"
    id="email"
    variant="filled"
    fullWidth
    onChange={onChange}
    style={{ marginBottom: "5px" }}
    value={value}
  />
);

export const DisplayNameField = ({ onChange, value }) => (
  <TextField
    color="secondary"
    label="name"
    id="display name"
    variant="filled"
    fullWidth
    onChange={onChange}
    style={{ marginBottom: "5px" }}
    value={value}
  />
);

export const PasswordField = ({ onChange, value }) => (
  <TextField
    autocCmplete="off"
    color="secondary"
    label="password"
    id="password"
    type="password"
    variant="filled"
    fullWidth
    onChange={onChange}
    style={{ marginBottom: "5px" }}
    value={value}
  />
);

export const ConfirmPasswordField = ({ onChange, value }) => (
  <TextField
    color="secondary"
    label="confirm password"
    id="confirmPassword"
    type="password"
    variant="filled"
    fullWidth
    onChange={onChange}
    style={{ marginBottom: "5px" }}
    value={value}
  />
);
